import { createAction } from 'redux-actions';
import {
  generateActionTypes,
  generateFetchActionTypes,
} from 'shared/actions/helpers';

const module = 'PROJECT_CONTAINER';

export const PROJECT_CONTAINER = {
  ...generateActionTypes(module, [
    'ENTER_PROJECT_FOLDER',
    'SELECT_ASSETS',
    'MOVE_ASSETS',
    'VERSION_ASSET',
    'ASSETS_LOADED',
    'OPEN_EDIT_PROJECT_FORM',
    'SET_ASSETS_SORT',
    'SET_ASSETS_VIEW_TYPE',
    'UPLOAD_INPUT_ITEMS',
    'UPLOAD_DROPPED_ITEMS',
    'CREATE_ASSET_PLACEHOLDERS',
    'SET_ASSETS_SCROLL_TOP',
    'CUSTOM_SORT_ASSETS',
    'RENAME_ASSET',
    'CONFIRM_DELETE_SELECTED_ASSETS',
    'SET_PLACEHOLDER_BUNDLE_CHILD_ASSET_ID',
  ]),
  ...generateFetchActionTypes(module, ['FETCH_ASSETS'], true),
};

export const enterProjectFolder = createAction(
  PROJECT_CONTAINER.ENTER_PROJECT_FOLDER,
  (projectId, folderId, didProjectChange, didFolderChange) => ({
    projectId,
    folderId,
    didProjectChange,
    didFolderChange,
  })
);

/**
 * Creates action for getting assets in a folder.
 * @param {number} folderId - The id of the folder to fetch from.
 * @param {Object} options - The options to fetch the assets with.
 * @param {number} options.page - The page of assets to fetch.
 * @returns {Object} Redux action.
 */
export const fetchAssets = createAction(
  PROJECT_CONTAINER.FETCH_ASSETS.BASE,
  (folderId, options) => ({ folderId, options })
);

export const confirmDeleteSelectedAssets = createAction(
  PROJECT_CONTAINER.CONFIRM_DELETE_SELECTED_ASSETS,
  (page, position) => ({
    page,
    position,
  })
);

export const resetAssets = createAction(
  PROJECT_CONTAINER.FETCH_ASSETS.RESET,
  (preserveMetadata) => ({ preserveMetadata })
);

/**
 * Creates an action to set the assets that are currently selected.
 * @param {string[]} assetId - Ids of the selected assets
 * @returns {Action} Created action.
 */
export const selectAssets = createAction(
  PROJECT_CONTAINER.SELECT_ASSETS,
  (assetIds = []) => ({
    assetIds,
  })
);

/**
 * Move some assets into a folder, and then refresh the folder.
 * @param {string[]} assetIds - Ids of assets to move.
 * @param {string} folderId - Id of the folder to move into.
 * @param {Function} [onDone] - Callback for when the API call completes.
 * @returns {Action} Created action.
 */
export const moveAssets = createAction(
  PROJECT_CONTAINER.MOVE_ASSETS,
  (assetIds = [], folderId, onDone) => ({
    assetIds,
    folderId,
    onDone,
  })
);

/**
 * Version an asset onto another asset.
 * @param {string} assetIds - Id of asset to version.
 * @param {string} targetAssetId - Id of the asset to version ont.
 * @param {Function} [onDone] - Callback for when the API call completes.
 */
export const versionAsset = createAction(
  PROJECT_CONTAINER.VERSION_ASSET,
  (assetId, targetAssetId, onDone) => ({
    assetId,
    targetAssetId,
    onDone,
  })
);

/**
 * Trigger when the assets are loaded.
 */
export const assetsLoaded = createAction(
  PROJECT_CONTAINER.ASSETS_LOADED,
  () => ({})
);

/**
 * Sets the sort field for assets
 * @param {Object} sortOption - The option to sort by. See sortOptions.js.
 * @returns {Object} Action.
 */
export const sortAssets = createAction(
  PROJECT_CONTAINER.SET_ASSETS_SORT,
  (sortOption, isDescending) => ({
    sortOption,
    isDescending,
  })
);

/**
 * Sets the view type for assets
 * @export
 * @param {*} viewType
 * @returns
 */
export const setAssetsViewType = createAction(
  PROJECT_CONTAINER.SET_ASSETS_VIEW_TYPE,
  (viewType) => ({ viewType })
);

/**
 * Uploads files and folders dragged and dropped into a given folder.
 * @param {string} folderId - Id of the folder to upload into.
 * @param {File[]} files - `event.dataTransfer.files`.
 * @param {DataTransferItem[]} dataTransferItems - `event.dataTransfer.items`.
 * @returns {Object} Action.
 */
export const uploadDroppedItems = createAction(
  PROJECT_CONTAINER.UPLOAD_DROPPED_ITEMS,
  (folderId, files, items) => ({ folderId, files, items })
);

/**
 * Upload files or a single folder via an Input element.
 * @param {string} folderId - Id of the folder to upload into.
 * @param {boolean} isFolder - `true` if uploading a folder.
 * @returns {Object} Action.
 */
export const selectAndUploadItems = createAction(
  PROJECT_CONTAINER.UPLOAD_INPUT_ITEMS,
  (folderId, isFolder = false) => ({ folderId, isFolder })
);

/**
 * Creates placeholder assets to optimistically update the assets grid.
 * @param {Object} assets - Map of placeholder ids to assets.
 * @returns {Object} Action.
 */
export const createAssetPlaceholders = createAction(
  PROJECT_CONTAINER.CREATE_ASSET_PLACEHOLDERS,
  (assets) => ({ assets })
);

/**
 * Stores the scroll top of the grid for restoring at a later time.
 * @param {Number} value - Scroll top value.
 * @returns {Object} Action.
 */
export const setAssetsScrollTop = createAction(
  PROJECT_CONTAINER.SET_ASSETS_SCROLL_TOP,
  (value) => ({ value })
);

/**
 * Custom sorts assets into a given cell index.
 * @param {string[]} - Ids of asses to custom sor.
 * @param {number} - Index of the page result array to move them to.
 * @returns {Action} Action.
 */
export const customSortAssets = createAction(
  PROJECT_CONTAINER.CUSTOM_SORT_ASSETS,
  (assetIds, cellIndex) => ({
    assetIds,
    cellIndex,
  })
);

export const renameAsset = createAction(
  PROJECT_CONTAINER.RENAME_ASSET,
  (id, name) => ({ id, name })
);

/**
 * Adds the given assetId to the placeholder-bundle's
 * `childAsset` property.
 * @param {string} bundlePlaceholderId - The placeholderId of the bundle
 * @param {string} assetId - The asset's id
 */
export const setPlaceholderBundleAssetId = createAction(
  PROJECT_CONTAINER.SET_PLACEHOLDER_BUNDLE_CHILD_ASSET_ID,
  (bundlePlaceholderId, assetId) => ({ bundlePlaceholderId, assetId })
);

/**
 * @param {string} projectId - The project's id
 * @returns {Object} Action.
 */
export const openEditProjectForm = createAction(
  PROJECT_CONTAINER.OPEN_EDIT_PROJECT_FORM,
  (projectId) => ({ projectId })
);
