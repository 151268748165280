import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { debounce, get, identity, partial, map } from 'lodash';
import { FlexItem } from 'styled-flex-component';
import styled, { css } from 'styled-components';
import Range from 'components/Range';
import { Select, MenuRadio, Tooltip } from '@frameio/vapor';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '@frameio/components/src/styled-components/Button';
import InfoIcon from '@frameio/components/src/svgs/icons/24/info-filled.svg';
import ColorPicker from '@frameio/components/src/styled-components/ColorPicker';
import TextShadowSVG from '@frameio/components/src/svgs/icons/16/text-shadow.svg';
import LeftAlignSVG from '@frameio/components/src/svgs/icons/16/left-align.svg';
import RightAlignSVG from '@frameio/components/src/svgs/icons/16/right-align.svg';
import CenterAlignSVG from '@frameio/components/src/svgs/icons/16/center-align.svg';
import ArrowSVG from '@frameio/components/src/svgs/icons/arrow-up.svg';
import NoSVG from '@frameio/components/src/svgs/icons/24/no.svg';
import TextInput from '@frameio/components/src/styled-components/TextInput';
import track from 'analytics';
import WatermarkCheckbox from '../WatermarkCheckbox';
import SessionWatermarkTemplateButtonSelectInput from '../SessionWatermarkTemplateButtonSelectInput';
import useRemoveWatermarkBlock from '../useRemoveWatermarkBlock';
import {
  Text,
  StyledFlex,
  StyledIcon,
  VaporSelectChildContent,
} from '../styles';
import {
  DATA_POINTS,
  DATA_POINT_DEFAULT_VALUES,
  TEXT_SIZE_LABELS,
} from '../constants';

const OPACITY_MIN_VALUE = 0.1;

const StyledColorPicker = styled(ColorPicker)`
  height: ${(p) => p.theme.spacing.units(5)};
  display: flex;
  align-items: center;
  background: transparent;

  :before {
    content: '#';
    color: ${(p) => p.theme.color.graphiteGray};
    display: inline-block;
    margin-top: 1px;
    margin-right: ${(p) => p.theme.spacing.micro};
  }

  button {
    height: ${(p) => p.theme.spacing.medium};
    width: ${(p) => p.theme.spacing.medium};
  }

  ${TextInput} {
    background: transparent;
  }

  /* HACK: transition and background-color copied from https://github.com/Frameio/web-components/blob/716e669ce5bdd6b0aca2d0aec1221f95d3042a14/src/styled-components/Button/Button.js#L52 */
  transition: background-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease,
    opacity 0.2ms ease;
  &:active,
  &:focus,
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const StyledDiv = styled.div`
  padding: ${(p) => p.theme.spacing.units(4)};
  padding-top: ${(p) => p.theme.spacing.units(1)};
  padding-bottom: ${(p) => p.theme.spacing.units(2)};
  overflow: hidden;
`;

const StyledUl = styled.ul`
  border-bottom: 1px solid ${(p) => p.theme.color.coldWhite};
  list-style: none;
  margin: 0 0 ${(p) => p.theme.spacing.medium} 0;
  padding-bottom: ${(p) => p.theme.spacing.medium};
`;

const StyledLi = styled.li`
  margin-bottom: ${(p) => p.theme.spacing.units(1)};
`;

const SafariPositionVaporTooltipWrapper = styled.div`
  position: relative;
`;

// HACK: override hover style on any `<Button forwardAs='a' />`
const disabledButtonStyles = css`
  opacity: 0.5;
  cursor: normal !important;

  &:hover {
    background-color: white !important;
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: ${(p) => p.theme.spacing.tiny};
  color: ${(p) => p.theme.color.error};
  width: 100%;
  ${(p) => p.disabled && disabledButtonStyles}
`;

export default function WatermarkTemplateSidebar(props) {
  const {
    activeBlock,
    errors,
    isDebugModeEnabled,
    setBlockValue,
    shouldAutofocusText,
    totalBlocksCount,
    watermarkTemplateId,
    isShareOnlyTemplate,
  } = props;

  const deleteBlock = useRemoveWatermarkBlock();
  const getBlockValue = partial(get, activeBlock);
  const getCustomText = partial(
    get,
    getBlockValue(['data_points', DATA_POINTS.CUSTOM_TEXT])
  );

  // if the custom text field is disabled reset it to the default value
  const isCustomTextEnabled = getCustomText('enabled');
  const customTextEnabledRef = useRef(getCustomText('enabled'));

  useEffect(() => {
    const wasCustomTextEnabled = customTextEnabledRef.current;

    // let's update the ref assignment for future updates
    customTextEnabledRef.current = isCustomTextEnabled;

    if (wasCustomTextEnabled && !isCustomTextEnabled) {
      setBlockValue('data_points["custom_text"]', {
        order: getCustomText('order'),
        enabled: getCustomText('enabled'),
        value: DATA_POINT_DEFAULT_VALUES[DATA_POINTS.CUSTOM_TEXT],
      });
    }
  }, [isCustomTextEnabled, getCustomText, setBlockValue]);

  const trackCustomTextActionDebounced = React.useCallback(
    debounce(
      (nextValue) =>
        track('session-watermark-template-editor-action', {
          datapoint_name: DATA_POINTS.CUSTOM_TEXT,
          value: nextValue,
          watermark_template_id: watermarkTemplateId,
        }),
      1500
    ),
    [watermarkTemplateId]
  );

  const getOnChange = React.useCallback(
    (path, getValue = identity) => (...args) => {
      const nextValue = getValue(...args);
      track('session-watermark-template-editor-action', {
        datapoint_name: path,
        value: nextValue,
        watermark_template_id: watermarkTemplateId,
      });
      setBlockValue(path, nextValue);
    },
    [setBlockValue, watermarkTemplateId]
  );

  return (
    <StyledDiv>
      {!isShareOnlyTemplate && (
        <StyledUl data-test-id="data-points-list">
          <StyledLi>
            <WatermarkCheckbox
              name={DATA_POINTS.USERNAME}
              order={0}
              data-test-id="username-toggle"
              value={DATA_POINT_DEFAULT_VALUES.username}
              label="Name"
            />
          </StyledLi>
          <StyledLi>
            <WatermarkCheckbox
              name={DATA_POINTS.EMAIL}
              order={1}
              data-test-id="email-toggle"
              value={DATA_POINT_DEFAULT_VALUES.email}
              label="Email"
            />
          </StyledLi>
          <StyledLi>
            <WatermarkCheckbox
              name={DATA_POINTS.IP_ADDRESS}
              order={2}
              data-test-id="ip-address"
              value={DATA_POINT_DEFAULT_VALUES.ip_address}
              label="IP address"
            />
          </StyledLi>
          <StyledLi>
            <WatermarkCheckbox
              name={DATA_POINTS.TIMESTAMP}
              order={3}
              data-test-id="timestamp-toggle"
              value={DATA_POINT_DEFAULT_VALUES.timestamp()}
              label="Date time"
            />
          </StyledLi>
          <StyledLi>
            <WatermarkCheckbox
              name={DATA_POINTS.USER_INPUT}
              value={DATA_POINT_DEFAULT_VALUES.user_input}
              label="Link creator custom text"
              order={4}
            >
              <Tooltip
                title={
                  <React.Fragment>
                    Allow users to input their own <br />
                    watermark text when sharing
                  </React.Fragment>
                }
                variant="dark"
                placement="top"
              >
                <Text as={StyledFlex} inline gutter={[0, 0, -0.5, 1]}>
                  <StyledIcon
                    as={InfoIcon}
                    width={24}
                    height={24}
                    color="slateGray"
                  />
                </Text>
              </Tooltip>
            </WatermarkCheckbox>
          </StyledLi>
          <StyledLi>
            <WatermarkCheckbox
              name={DATA_POINTS.CUSTOM_TEXT}
              order={5}
              data-test-id="custom-text-toggle"
              value={getCustomText('value') || ''}
              label="Custom text"
            >
              <AnimatePresence initial={false}>
                {getCustomText('enabled') && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0, overflow: 'hidden' }}
                    animate={{ opacity: 1, height: '100%' }}
                    style={{ width: '100%' }}
                  >
                    <FlexItem basis="100%">
                      <StyledFlex column gutter={[1.5, 0, 0, 0]}>
                        <TextInput
                          data-test-id="custom-text-input"
                          autoFocus={shouldAutofocusText}
                          value={getCustomText('value') || ''}
                          placeholder="Custom text here…"
                          error={get(errors, [
                            'data_points',
                            DATA_POINTS.CUSTOM_TEXT,
                            'value',
                          ])}
                          onChange={(evt) => {
                            trackCustomTextActionDebounced(evt.target.value);
                            setBlockValue('data_points["custom_text"]', {
                              type: DATA_POINTS.CUSTOM_TEXT,
                              value: evt.target.value,
                              enabled: true,
                              order: 5,
                            });
                          }}
                        />
                      </StyledFlex>
                    </FlexItem>
                  </motion.div>
                )}
              </AnimatePresence>
            </WatermarkCheckbox>
          </StyledLi>
        </StyledUl>
      )}
      <StyledFlex alignStart gutter={[0, 0, 2, 0]} gutterColumn={1.5}>
        <FlexItem basis="50%">
          <Text as="p" variant="button" gutter={[0, 0, 1, 0]}>
            Text size
          </Text>
          <Select
            shouldUsePortal
            data-test-id="text-size-dropdown"
            value={getBlockValue('font_size')}
            onChange={getOnChange('font_size')}
            content={map(TEXT_SIZE_LABELS, (label, value) => (
              <MenuRadio name={value}>{label}</MenuRadio>
            ))}
          >
            <VaporSelectChildContent height={5}>
              {TEXT_SIZE_LABELS[getBlockValue('font_size')]}
            </VaporSelectChildContent>
          </Select>
        </FlexItem>
        <FlexItem basis="50%">
          {/* TODO: look into form validation for missing text_color */}
          <Text as="p" variant="button" gutter={[0, 0, 1, 0]}>
            Color
          </Text>
          <StyledColorPicker
            data-test-id="text-color-picker"
            placeholderColor="FFFFFF"
            color={getBlockValue('text_color', '').substr(1)}
            onChange={getOnChange('text_color', (color) =>
              color ? `#${color}` : undefined
            )}
          />
        </FlexItem>
      </StyledFlex>

      <StyledFlex
        alignCenter
        justifyBetween
        gutter={[0, 0, 3, 0]}
        borderColor="silver"
        padding={[0.5, 1.5, 0.5, 1.5]}
      >
        <Text as="p" color="coolBlack" variant="body" gutter={[0, 1.5, 0, 0]}>
          Opacity
        </Text>
        <Range
          min={OPACITY_MIN_VALUE}
          max={1}
          step={0.01}
          value={Number(getBlockValue('alpha', 1))}
          data-test-id="opacity-range"
          onChange={getOnChange('alpha')}
        />
        <Text
          color="graphiteGray"
          variant="caption"
          gutter={[0, 0, 0, 1.5]}
          style={{
            fontVariantNumeric: 'tabular-nums',
            textAlign: 'right',
            fontSize: '14px',
          }}
        >
          {Math.round(getBlockValue('alpha', 1) * 100)
            .toString()
            .padStart(3, '\u2007')
            .concat('%')}
        </Text>
      </StyledFlex>

      <StyledFlex alignCenter gutter={[0, 0, 4, 0]} gutterColumn={1.5}>
        <FlexItem basis="40%" justifyStart>
          <Text as="p" variant="button" gutter={[0, 0, 1, 0]}>
            Text shadow
          </Text>
          <SessionWatermarkTemplateButtonSelectInput
            value={getBlockValue('text_shadow')}
            onChange={getOnChange('text_shadow')}
            options={[
              { text: <NoSVG />, value: false },
              { text: <TextShadowSVG />, value: true },
            ]}
          />
        </FlexItem>
        <FlexItem basis="60%" justifyStart>
          <Text as="p" variant="button" gutter={[0, 0, 1, 0]}>
            Text scroll
          </Text>
          <SessionWatermarkTemplateButtonSelectInput
            value={getBlockValue('scroll_text')}
            onChange={getOnChange('scroll_text')}
            options={[
              { text: <NoSVG />, value: 'none' },
              { text: <StyledIcon as={ArrowSVG} rotate={270} />, value: 'rtl' },
              { text: <StyledIcon as={ArrowSVG} rotate={90} />, value: 'ltr' },
            ]}
          />
        </FlexItem>
      </StyledFlex>

      {/* TODO: remove later */}
      {isDebugModeEnabled && (
        <React.Fragment>
          <StyledFlex alignStart column gutter={[0, 0, 4, 0]}>
            <Text as="p" variant="button" gutter={[0, 0, 1, 0]}>
              sessionWatermarkTemplateId
            </Text>
            <TextInput readOnly value={getBlockValue('id') || ''} />
          </StyledFlex>
          <StyledFlex alignStart gutter={[0, 0, 4, 0]} gutterColumn={1.5}>
            <FlexItem basis="50%" justifyStart>
              <Text as="p" variant="button" gutter={[0, 0, 1, 0]}>
                Alignment
              </Text>
              <SessionWatermarkTemplateButtonSelectInput
                disabled={getBlockValue('scroll_text') !== 'none'}
                value={getBlockValue('text_alignment')}
                onChange={getOnChange('text_alignment')}
                options={[
                  { text: <LeftAlignSVG />, value: 'left' },
                  { text: <CenterAlignSVG />, value: 'center' },
                  { text: <RightAlignSVG />, value: 'right' },
                ]}
              />
            </FlexItem>
            <FlexItem basis="50%">
              <Text as="p" variant="button" gutter={[0, 0, 1, 0]}>
                Position alignment
              </Text>
              <Select
                data-test-id="text-size-dropdown"
                value={getBlockValue('position_reference_point')}
                onChange={getOnChange('position_reference_point')}
                content={[
                  'top_left',
                  'top_center',
                  'top_right',
                  'middle_left',
                  'middle_center',
                  'middle_right',
                  'bottom_left',
                  'bottom_center',
                  'bottom_right',
                ].map((value) => (
                  <MenuRadio name={value} key={value}>
                    {value}
                  </MenuRadio>
                ))}
              >
                <VaporSelectChildContent height={5}>
                  {getBlockValue('position_reference_point')}
                </VaporSelectChildContent>
              </Select>
            </FlexItem>
          </StyledFlex>
        </React.Fragment>
      )}
      {!isShareOnlyTemplate && (
        <SafariPositionVaporTooltipWrapper>
          <Tooltip
            disabled={totalBlocksCount > 1}
            title={
              <React.Fragment>
                Templates must contain <br />
                at least one text block
              </React.Fragment>
            }
            variant="dark"
          >
            <StyledButton
              data-test-id="delete-block"
              disabled={totalBlocksCount < 2}
              forwardedAs="a"
              onClick={deleteBlock}
            >
              Delete block
            </StyledButton>
          </Tooltip>
        </SafariPositionVaporTooltipWrapper>
      )}
    </StyledDiv>
  );
}

WatermarkTemplateSidebar.defaultProps = {
  errors: {},
  isDebugModeEnabled: false,
  totalBlocksCount: 0,
};

WatermarkTemplateSidebar.propTypes = {
  activeBlock: PropTypes.object,
  totalBlocksCount: PropTypes.number.isRequired,
  errors: PropTypes.object,
  isDebugModeEnabled: PropTypes.bool,
  shouldAutofocusText: PropTypes.bool,
  setBlockValue: PropTypes.func.isRequired,
  watermarkTemplateId: PropTypes.string,
};
