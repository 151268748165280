import React from 'react';
import PropTypes from 'prop-types';
import { flatMap, get, isObject, keys, isEmpty } from 'lodash';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import Flex, { FlexItem } from 'styled-flex-component';
import { Helmet } from 'react-helmet-async';
import Button from '@frameio/components/src/styled-components/Button';
import CancelSVG from '@frameio/components/src/svgs/icons/16/cancel.svg';
import TextInput, {
  ErrorMessage,
} from '@frameio/components/src/styled-components/TextInput';
import { Select, MenuRadio, Tooltip } from '@frameio/vapor';
import track from 'analytics';
import useGetElementSize from 'utils/useGetElementSize';
import ProvideElementSize from 'utils/ProvideElementSize';
import SessionWatermarkTemplateBlockDropTarget from './SessionWatermarkTemplateBlockDropTarget';
import SessionWatermarkTemplateDotBackground from './SessionWatermarkTemplateDotBackground';
import WatermarkBlockSelector from './WatermarkBlockSelector';
import WatermarkTemplateSidebar from './WatermarkTemplateSidebar/WatermarkTemplateSidebar';
import SessionWatermarkTemplateAddButton from './SessionWatermarkTemplateAddButton';
import {
  Text,
  StyledCheckbox,
  StyledIcon,
  StyledButton,
  StyledFlex,
  VaporSelectChildContent,
} from './styles';
import {
  MODAL_HEIGHT,
  MODAL_MAX_WIDTH,
  SIDEPANEL_WIDTH_MAX,
  SIDEPANEL_WIDTH_MIN,
  RESTRICTED_EDITOR_MESSAGE,
  RESTRICTED_EDITOR_MESSAGE_NON_ADMIN,
} from './constants';
import {
  PREVIEW_IMAGE_UPDATE_TIMEOUT,
  placeholdersByAspectRatio,
  aspectRatioOrder,
} from './SessionWatermarkTemplateEditor';

import useCloseHotkey from './useCloseHotkey';

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: auto minmax(
      ${SIDEPANEL_WIDTH_MIN},
      ${SIDEPANEL_WIDTH_MAX}
    );
  grid-template-rows: ${(p) => p.theme.spacing.units(8)} auto ${(p) =>
      p.theme.spacing.units(8)};
  width: 96vw;
  height: ${MODAL_HEIGHT};
  max-width: ${MODAL_MAX_WIDTH};
  max-height: 96vh;
`;

const upperScrollAffordanceStyles = css`
  overflow-y: scroll;
  background-repeat: no-repeat;
  background-color: white;

  background-position: top, top;
  background-attachment: local, scroll;
  background-size: 100% calc(5 * ${(p) => p.theme.spacing.medium}),
    100% ${(p) => p.theme.spacing.medium};
  background-image: ${(p) => `
    linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255, 0)),
    linear-gradient(
      to bottom,
      ${rgba(p.theme.color.graphiteGray, 0.07)} 0%,
      ${rgba(p.theme.color.graphiteGray, 0)} 100%
    )`};
`;

const GridLayoutCell = styled.div`
  padding: ${(p) =>
    p.isPaddingDisabled
      ? ''
      : `${p.theme.spacing.units(1)} ${p.theme.spacing.units(3)}`};
  grid-area: ${(p) => p.gridArea};
  align-self: ${(p) => p.alignSelf || 'center'};
  justify-self: ${(p) => p.justifySelf || 'normal'};
  border-left: ${(p) =>
    p.isBorderedOnLeft ? `1px solid ${p.theme.color.accentGrayLight}` : 'none'};

  ${(p) => p.isScrollContainer && upperScrollAffordanceStyles}
`;

const GridLayoutCellCenter = styled(GridLayoutCell)`
  width: 100%;
  height: 100%;
`;

const GridLayoutCellCenterTileChild = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

const GridPreviewImageWrapper = styled.div`
  position: relative;
`;

const LoadingScrim = styled.div`
  background: linear-gradient(
      86.91deg,
      #e5e6ed 1.31%,
      #eeeff2 34.61%,
      rgba(255, 255, 255, 0.657) 53%,
      rgba(255, 255, 255, 0) 98.65%
    ),
    linear-gradient(0deg, #e3e6ec, #e3e6ec);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  pointer-events: none;
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  transition: opacity 500ms ease;
`;

/* HACK: band aid for ENT-2265 */
const StyledTextInputContainer = styled.div`
  height: 52px;
  overflow-y: visible;

  ${ErrorMessage} {
    margin-top: 2px;
  }
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
  padding-left: ${(p) => (p.error ? undefined : 0)};
  border-color: ${(p) => (p.error ? undefined : 'white')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: all 25ms ease-out, padding 150ms ease;

  ${(p) =>
    !p.disabled &&
    `
  &:active,
  &:focus,
  &:hover {
    padding-left: ${p.theme.spacing.tiny};
  }

  &:hover {
    border-color: ${p.error ? undefined : p.theme.color.silver};
  }

  &:active,
  &:focus {
    border-color: ${p.error ? undefined : p.theme.color.brand};
    color: ${p.theme.color.black};
  }
  `}

  /* HACK: this specificity is required to override base TextInput */
  && {
    ${(p) => p.theme.fontStyle.headerS}
    background-color: ${(p) => p.disabled && 'transparent'};
  }
  &::placeholder {
    color: ${(p) => p.theme.color.lightGray};
    ${(p) => p.theme.fontStyle.headerS}
  }
`;

const SaveButtonEventWrapper = styled.div`
  display: inline-block;
  padding: ${(p) => p.theme.spacing.micro};
`;

const AspectRatioText = styled(Text)`
  white-space: nowrap;
`;

const StyledSelect = styled(Select)`
  min-width: ${(p) => p.theme.spacing.units(21)};
`;

const StyledVaporSelectChildContent = styled(VaporSelectChildContent)`
  width: ${(p) => p.theme.spacing.units(11)};
`;

const StyledMenuRadio = styled(MenuRadio)`
  height: ${(p) => p.theme.spacing.units(3.85)};
`;

const StyledFlexItem = styled(FlexItem)`
  position: relative;
  margin-right: ${(p) => p.theme.spacing.units(4)};
`;

const StyledControlsFlexItem = styled(FlexItem)`
  margin-right: ${(p) => p.theme.spacing.units(3)};
`;

const StyledText = styled(Text)`
  display: inline-block;
  padding: 0 ${(p) => p.theme.spacing.micro} ${(p) => p.theme.spacing.micro};
`;

// convert a deep nested object into a flat array of non-object/array values
// we can use this to recursively extract deeply nested error messages
// into a list of strings
const mapValuesFlat = (obj) => {
  return flatMap(obj, (v) => {
    if (isObject(v)) {
      return mapValuesFlat(v);
    }

    return v;
  });
};

function SessionWatermarkTemplateGridLayout(props) {
  const {
    aspectRatio,
    aspectRatioChange,
    currentViewportSize,
    dirty,
    effectiveInitialValues,
    errors,
    handleSubmit,
    isDebugModeEnabled,
    isDevToolsAllowed,
    isFrameGuideEnabled,
    isShareOnlyTemplate,
    isValid,
    isAdmin,
    nameFieldRef,
    onAspectRatioChange,
    onClose,
    onDebugModeChange,
    onFrameGuideChange,
    onPreviewImageUpdate,
    previewImageIsUpdating,
    setAspectRatio,
    setFieldValue,
    values,
  } = props;
  const [gridPreviewImageRef, previewImageBounds] = useGetElementSize([
    aspectRatioChange,
    ...currentViewportSize,
  ]);

  const [previewImageHasLoaded, setPreviewImageHasLoaded] = React.useState(
    false
  );

  // Delaying this state change helps prevent a flash of empty content when the preview loads immediately
  const handlePreviewImageLoaded = () => {
    setTimeout(() => {
      setPreviewImageHasLoaded(true);
    }, PREVIEW_IMAGE_UPDATE_TIMEOUT);
  };

  useCloseHotkey(dirty);

  // This effect is used to help define the exact dimensions of where the background grid should draw by waiting until the asset has time to render.
  // It accomplishes this by waiting a brief period of time before telling the local state of the parent via callback that the asset is done updating its bounds, after which the grid will finish rendering.
  // A useEffect instead of useLayoutEffect fires too soon, and causes minor inaccuracies.
  React.useLayoutEffect(() => {
    setTimeout(() => {
      onPreviewImageUpdate(false);
    }, PREVIEW_IMAGE_UPDATE_TIMEOUT);
  }, [previewImageBounds, onPreviewImageUpdate]);

  const setBlockValue = React.useCallback(
    (field, value) =>
      setFieldValue(
        `watermark_blocks[${values.activeBlockId}].${field}`,
        value
      ),
    [setFieldValue, values.activeBlockId]
  );

  return (
    <GridLayout>
      <GridLayoutCell gridArea="1 / 1 / 2 / 2">
        <Flex alignCenter justifyBetween={!isShareOnlyTemplate}>
          <FlexItem grow={isShareOnlyTemplate && !isShareOnlyTemplate ? 0 : 1}>
            <StyledTextInputContainer>
              <StyledTextInput
                data-test-id="template-name-input"
                disabled={isShareOnlyTemplate}
                error={errors.name}
                onBlur={(evt) => {
                  if (!evt.target.value) {
                    setFieldValue('name', effectiveInitialValues.name);
                  }
                }}
                onChange={(e) => {
                  setFieldValue('name', e.target.value);
                }}
                onFocus={(evt) => {
                  evt.target.select();
                }}
                placeholder="Untitled watermark template"
                ref={nameFieldRef}
                type="text"
                value={values.name}
              />
            </StyledTextInputContainer>
          </FlexItem>
          {isShareOnlyTemplate && (
            <FlexItem noShrink>
              <StyledText variant="headerS" color="brand">
                [Modifying template]
              </StyledText>
            </FlexItem>
          )}
          {!isShareOnlyTemplate && (
            <FlexItem>
              <SessionWatermarkTemplateAddButton
                label="Create new text block"
                disabled={!previewImageHasLoaded}
              />
            </FlexItem>
          )}
        </Flex>
      </GridLayoutCell>
      <GridLayoutCellCenter
        gridArea="2 / 1 / 3 / 2"
        alignSelf="normal"
        justifySelf="center"
      >
        <ProvideElementSize>
          {({ width, height }) => (
            <GridLayoutCellCenterTileChild justifyCenter alignCenter>
              <LoadingScrim isVisible={!previewImageHasLoaded} />
              <SessionWatermarkTemplateDotBackground
                isVisible={!previewImageIsUpdating}
                previewImageBounds={previewImageBounds}
                transitionLength={PREVIEW_IMAGE_UPDATE_TIMEOUT}
                width={width}
                height={height}
              />
              <GridPreviewImageWrapper ref={gridPreviewImageRef}>
                <SessionWatermarkTemplateBlockDropTarget
                  maxWidth={width}
                  maxHeight={height}
                  blockIds={keys(values.watermark_blocks)}
                  imgNaturalHeight={get(placeholdersByAspectRatio, [
                    aspectRatio,
                    'naturalHeight',
                  ])}
                  imgNaturalWidth={get(placeholdersByAspectRatio, [
                    aspectRatio,
                    'naturalWidth',
                  ])}
                  imgSrc={get(placeholdersByAspectRatio, [aspectRatio, 'src'])}
                  isDebugModeEnabled={isDebugModeEnabled}
                  isFrameGuideEnabled={isFrameGuideEnabled}
                  isShareOnlyTemplate={isShareOnlyTemplate}
                  updateBlock={(id, value) =>
                    setFieldValue(`watermark_blocks[${id}]`, {
                      ...values.watermark_blocks[id],
                      ...value,
                    })
                  }
                  onLoad={handlePreviewImageLoaded}
                />
                <Helmet>
                  {aspectRatioOrder.map((entry) => (
                    <link
                      as="image"
                      href={get(placeholdersByAspectRatio, [entry, 'src'])}
                      key={entry}
                      rel="preload"
                    />
                  ))}
                </Helmet>
              </GridPreviewImageWrapper>
            </GridLayoutCellCenterTileChild>
          )}
        </ProvideElementSize>
      </GridLayoutCellCenter>
      <GridLayoutCell gridArea="3 / 1 / 4 / 2" justifySelf="stretch">
        <Flex alignCenter justifyStart>
          <FlexItem>
            <AspectRatioText as="label" variant="bodyM" gutter={[0, 1, 0, 0]}>
              Preview in:
            </AspectRatioText>
          </FlexItem>
          <StyledFlexItem grow={0}>
            <StyledSelect
              shouldUsePortal
              value={aspectRatio}
              offset={[0, 8]}
              onChange={(value) => {
                track(
                  'session-watermark-template-editor-aspect-ratio-updated',
                  { value, watermark_template_id: values.id }
                );

                onPreviewImageUpdate(true);
                // The timeout lets the grid fade out before the asset shape changes
                setTimeout(() => {
                  setAspectRatio(value);
                  onAspectRatioChange();
                }, PREVIEW_IMAGE_UPDATE_TIMEOUT / 2);
              }}
              placement="bottom-end"
              content={aspectRatioOrder.map((ratio) => (
                <StyledMenuRadio
                  iconBefore={
                    <StyledIcon
                      as={get(placeholdersByAspectRatio, [ratio, 'icon'])}
                      color="graphiteGray"
                      height={16}
                      width={16}
                    />
                  }
                  key={ratio}
                  name={ratio}
                >
                  <Text gutter={[0, 0, 0, 0.5]}>{ratio}</Text>
                </StyledMenuRadio>
              ))}
            >
              <StyledVaporSelectChildContent height={4}>
                {aspectRatio}
              </StyledVaporSelectChildContent>
            </StyledSelect>
          </StyledFlexItem>
          <Flex wrap="true">
            {!isShareOnlyTemplate && (
              <StyledControlsFlexItem>
                <Text as="label" variant="bodyM">
                  <StyledCheckbox
                    size="16px"
                    onChange={() => onFrameGuideChange(!isFrameGuideEnabled)}
                    checked={isFrameGuideEnabled}
                    gutter={[0, 1.5, 0, 0]}
                  />
                  Show safe zone
                </Text>
              </StyledControlsFlexItem>
            )}

            {isDevToolsAllowed && (
              <StyledControlsFlexItem>
                <Text as="label" variant="bodyM">
                  <StyledCheckbox
                    size="16px"
                    onChange={() => onDebugModeChange(!isDebugModeEnabled)}
                    checked={isDebugModeEnabled}
                    gutter={[0, 1.5, 0, 0]}
                  />
                  Show debugger
                </Text>
              </StyledControlsFlexItem>
            )}
          </Flex>
          <FlexItem grow={10} />
          <FlexItem>
            <Tooltip
              disabled={isValid}
              placement="top"
              title={mapValuesFlat(errors)[0]}
              className="save-btn-tooltip"
              variant="dark"
              shouldUsePortal
            >
              <SaveButtonEventWrapper data-test-id="save-btn-evt-wrapper">
                <StyledButton
                  primary
                  data-test-id="save-btn"
                  onClick={handleSubmit}
                  disabled={
                    !isEmpty(errors) || !dirty || !previewImageHasLoaded
                  }
                  type="submit"
                >
                  Save changes
                </StyledButton>
              </SaveButtonEventWrapper>
            </Tooltip>
          </FlexItem>
        </Flex>
      </GridLayoutCell>

      <GridLayoutCell
        gridArea="1 / 2 / 2 / 3"
        alignSelf="stretch"
        isBorderedOnLeft
        isPaddingDisabled
      >
        <StyledFlex
          justifyBetween
          alignStart
          padding={[2, 2, 0, 4]}
          gutter={[0, 0, 1, 0]}
        >
          <FlexItem>
            <Text as="h2" variant="headerXS" gutter={[0.25, 0, 0, 0]}>
              Edit selected block
            </Text>
          </FlexItem>
          <FlexItem>
            <Button
              text
              icon
              onClick={onClose}
              data-test-id="swte-close-button"
            >
              <StyledIcon height={16} width={16} as={CancelSVG} />
            </Button>
          </FlexItem>
        </StyledFlex>
      </GridLayoutCell>
      <GridLayoutCell
        gridArea="2 / 2 / 3 / 3"
        alignSelf="stretch"
        isBorderedOnLeft
        isPaddingDisabled
        isScrollContainer
      >
        {isShareOnlyTemplate && (
          <Text
            as="div"
            backgroundColor="coldWhite"
            borderRadius="default"
            color="gray"
            padding={[1, 2]}
            gutter={[2, 4, 2]}
            data-test-id="restricted-message"
          >
            {isAdmin
              ? RESTRICTED_EDITOR_MESSAGE
              : RESTRICTED_EDITOR_MESSAGE_NON_ADMIN}
          </Text>
        )}
        <WatermarkTemplateSidebar
          isAdmin={isAdmin}
          activeBlock={get(values.watermark_blocks, [values.activeBlockId])}
          errors={get(errors, ['watermark_blocks', values.activeBlockId])}
          isDebugModeEnabled={isDebugModeEnabled}
          totalBlocksCount={Object.keys(values.watermark_blocks).length}
          setBlockValue={setBlockValue}
          isShareOnlyTemplate={isShareOnlyTemplate}
          shouldAutofocusText
          watermarkTemplateId={values.id}
        />
      </GridLayoutCell>
      <GridLayoutCell
        gridArea="3 / 2 / 4 / 3"
        alignSelf="stretch"
        isBorderedOnLeft
        isPaddingDisabled
      >
        <Flex center style={{ height: '100%' }}>
          <WatermarkBlockSelector />
        </Flex>
      </GridLayoutCell>
    </GridLayout>
  );
}

SessionWatermarkTemplateGridLayout.propTypes = {
  isDevToolsAllowed: PropTypes.bool,
  effectiveInitialValues: PropTypes.object,
  currentViewportSize: PropTypes.array,
  onPreviewImageUpdate: PropTypes.func,
  previewImageIsUpdating: PropTypes.bool,
  aspectRatioChange: PropTypes.bool,
  onAspectRatioChange: PropTypes.func,
  isFrameGuideEnabled: PropTypes.bool,
  onFrameGuideChange: PropTypes.func,
  isDebugModeEnabled: PropTypes.bool,
  onDebugModeChange: PropTypes.func,
  // nameFieldRef: PropTypes.any, // React.ref
  dirty: PropTypes.bool,
  // errors: PropTypes.any, // Formik<any>
  handleSubmit: PropTypes.func,
  isValid: PropTypes.bool,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};

export default SessionWatermarkTemplateGridLayout;
