import { combineReducers } from 'redux';
import ACCOUNT_MIGRATION from './actions';

// we do not have a way to know when the job has completed on the client side yet
// when we do, we will add a "SUCCEEDED" status
export const MIGRATION_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
};

function accountMigration(state = {}, action) {
  switch (action.type) {
    case ACCOUNT_MIGRATION.IN_PROGRESS: {
      return {
        ...state,
        [action.payload.accountId]: {
          status: MIGRATION_STATUS.IN_PROGRESS,
        },
      };
    }

    case ACCOUNT_MIGRATION.FAILED: {
      return {
        ...state,
        [action.payload.accountId]: {
          status: MIGRATION_STATUS.FAILED,
        },
      };
    }

    default:
      return state;
  }
}

export default combineReducers({
  accountMigration,
});
