import React from 'react';
import PropTypes from 'prop-types';
import { rgba } from 'polished';
import { zipWith, isEmpty } from 'lodash';
import styled, { css } from 'styled-components';
import DebugCoordinateMarker from './DebugCoordinateMarker';
import useReferencePointLayout from './useReferencePointLayout';
import useRemoveWatermarkBlock from './useRemoveWatermarkBlock';
import { VERTICAL_BORDER_PX, HORIZONTAL_BORDER_PX } from './constants';

const getReferencePoint = (x, y) => {
  let sectorX = 'unknown';
  if (x < 1 / 3) {
    sectorX = 'left';
  } else if (x < 2 / 3) {
    sectorX = 'center';
  } else {
    sectorX = 'right';
  }

  let sectorY = 'unknown';
  if (y < 1 / 3) {
    sectorY = 'top';
  } else if (y < 2 / 3) {
    sectorY = 'middle';
  } else {
    sectorY = 'bottom';
  }

  return `${sectorY}_${sectorX}`;
};

const getBorderStyles = (borderWidth) => css`
  padding: ${VERTICAL_BORDER_PX - borderWidth}px
    ${(p) => (p.isFullWidth ? 0 : HORIZONTAL_BORDER_PX - borderWidth)}px;
  border-color: ${(p) => {
    if (p.isDragPreview) return rgba(p.theme.color.accentGrayMedium, 0.5);
    if (p.isSelected && !p.isValid) return p.theme.color.error;
    if (!p.isValid) return rgba(p.theme.color.error, 0.5);
    if (p.isSelected) return p.theme.color.brand;
    return 'transparent';
  }};
  border-style: solid;
  border-width: ${borderWidth}px;
  border-left-width: ${(p) => (p.isFullWidth ? 0 : undefined)};
  border-right-width: ${(p) => (p.isFullWidth ? 0 : undefined)};
  transition: border-color 500ms ease-out;

  &:focus,
  &:hover {
    border-color: ${(p) =>
      p.isValid ? p.theme.color.brand : p.theme.color.error};
    outline: none;
    transition: box-shadow 250ms ease-out;
  }
`;

const StyledDiv = styled.div`
  position: absolute;
  display: inline-block;
  width: ${(p) =>
    p.isFullWidth
      ? `calc(100% - 2px)` // ENT-2323, ENT-2313
      : undefined};
  background: transparent;
  cursor: ${(p) => (p.isFullWidth ? 'ns-resize' : 'move')};
  will-change: opacity, transform;
  overflow: hidden;
  transition: ${(p) =>
    p.isDragPreview
      ? 'box-shadow 250ms ease-out, border-color 50ms ease'
      : 'box-shadow 250ms ease-out'};

  ${getBorderStyles(3)}

  box-shadow: ${(p) =>
    p.isDragPreview ? '0px 1px 1px rgba(91, 83, 255, 0.25)' : 'none'};

  &:active {
    ${getBorderStyles(2)}
    outline: none;
  }
`;

export const getTransformOrigin = (positionReferencePoint = '') => {
  const originByReferencePoint = {
    bottom_center: 'bottom center',
    bottom_left: 'bottom left',
    bottom_right: 'bottom right',
    middle_center: 'center center',
    middle_left: 'center left',
    middle_right: 'center right',
    top_center: 'top center',
    top_left: 'top left',
    top_right: 'top right',
  };
  return originByReferencePoint[positionReferencePoint] || '';
};

const SessionWatermarkTemplateBlockPositionContainer = React.forwardRef(
  (props, ref) => {
    const {
      children,
      isBlockCountAtMinimum,
      isDebugModeEnabled,
      isDragPreview,
      isShareOnlyTemplate,
      isSelected,
      isHidden,
      errors,
      isScrolling,
      positionMax,
      positionNormalized,
      positionOffset,
      positionReferencePoint,
      scale,
      textAlign,
      textSize,
      ...rest
    } = props;
    const deleteActiveBlock = useRemoveWatermarkBlock();

    const getNextRelativePosition = (offset, max, initial) =>
      offset / max + initial;
    const nextRelativePosition = zipWith(
      positionOffset,
      positionMax,
      positionNormalized,
      getNextRelativePosition
    );
    const nextReferencePoint = isDragPreview
      ? getReferencePoint(...nextRelativePosition)
      : positionReferencePoint;
    const { position, relativeOffset } = useReferencePointLayout({
      clientRectRef: ref,
      initialReferencePoint: positionReferencePoint,
      referencePoint: nextReferencePoint,
      positionMax,
      positionNormalized,
      positionOffset,
    });
    const style = React.useMemo(() => {
      const [x, y] = position;
      const [midpointOffsetX, midpointOffsetY] = relativeOffset;
      const originTranslation = isScrolling
        ? `translate(                 0 , ${midpointOffsetY}%)`
        : `translate(${midpointOffsetX}%, ${midpointOffsetY}%)`;
      const scaleTransformation = isScrolling
        ? `scale(1, ${scale})`
        : `scale(${scale})`;
      const nextTextAlign = isDragPreview
        ? nextReferencePoint.split('_')[1]
        : textAlign;
      return {
        left: isScrolling ? 1 : x || 0,
        top: y || 0,
        textAlign: isScrolling ? 'center' : nextTextAlign,
        transform: `${originTranslation} ${scaleTransformation}`,
        transformOrigin: getTransformOrigin(nextReferencePoint),
        opacity: isHidden && !isDebugModeEnabled ? 0 : 1,
      };
    }, [
      isDebugModeEnabled,
      isDragPreview,
      isHidden,
      isScrolling,
      nextReferencePoint,
      position,
      relativeOffset,
      scale,
      textAlign,
    ]);

    React.useEffect(() => {
      function handleKeyDown(e) {
        if (isShareOnlyTemplate) {
          return;
        }
        // Only run if the active block has focus
        // The activeElement lives outside the react world so the ref itself won't ever match the equality check. Comparing the classlist instead.
        if (document.activeElement.classList[0] !== ref.current.classList[0]) {
          return;
        }

        if (e.key === 'Delete' || e.key === 'Backspace') {
          // ENT-2183: We want to intercept these events and make sure no other parts of the UI have their hotkeys triggered
          e.preventDefault();
          e.stopImmediatePropagation();

          deleteActiveBlock();
        }
      }

      window.addEventListener('keydown', handleKeyDown, { capture: true });

      return () => {
        window.removeEventListener('keydown', handleKeyDown, { capture: true });
      };
    }, [deleteActiveBlock, isShareOnlyTemplate, ref]);

    return (
      <StyledDiv
        ref={ref}
        {...rest}
        isDragPreview={isDragPreview}
        isValid={isEmpty(errors)}
        isSelected={isSelected || isDragPreview}
        isFullWidth={isScrolling}
        style={style}
        textSize={textSize}
        tabIndex="0"
      >
        {children}

        {isDebugModeEnabled && (
          <DebugCoordinateMarker
            left={style.left}
            midpointRelativeOffset={relativeOffset}
            positionMax={positionMax}
            r={8}
            top={style.top}
          />
        )}
      </StyledDiv>
    );
  }
);

SessionWatermarkTemplateBlockPositionContainer.propTypes = {
  children: PropTypes.node,
  isDragPreview: PropTypes.bool,
  isShareOnlyTemplate: PropTypes.bool,
  isSelected: PropTypes.bool,
  isHidden: PropTypes.bool,
  positionOffset: PropTypes.arrayOf(PropTypes.number),
  positionMax: PropTypes.arrayOf(PropTypes.number).isRequired,
  value: PropTypes.shape({
    position_reference_point: PropTypes.string.isRequired,
    position_x: PropTypes.number.isRequired,
    position_y: PropTypes.number.isRequired,
    scroll_text: PropTypes.oneOf(['none', 'ltr', 'rtl']).isRequired,
  }),
};

SessionWatermarkTemplateBlockPositionContainer.defaultProps = {
  positionOffset: [0, 0],
  isSelected: false,
  isHidden: false,
};

export default SessionWatermarkTemplateBlockPositionContainer;
