import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDrag } from 'react-dnd';
import { partial, get } from 'lodash';
import { useFormikContext, useField } from 'formik';
import { types as dragTypes } from 'utils/dragAndDrop';
import { getEmptyImage } from 'react-dnd-html5-backend';
import SessionWatermarkTemplateBlock from './SessionWatermarkTemplateBlock';
import SessionWatermarkTemplateBlockPositionContainer from './SessionWatermarkTemplateBlockPositionContainer';

const StyledDiv = styled.div`
  position: static;
  width: 0;
  height: 0;
`;

const SessionWatermarkTemplateBlockDragSource = (props) => {
  const {
    height,
    id,
    isDebugModeEnabled,
    isShareOnlyTemplate,
    scale,
    width,
  } = props;
  const clientRectRef = React.useRef();
  const clientRect =
    clientRectRef && clientRectRef.current
      ? clientRectRef.current.getBoundingClientRect()
      : { width: 0, height: 0 };
  const blockDimensions = [clientRect.width, clientRect.height];

  const { setFieldValue, errors } = useFormikContext();
  const [{ value: activeId }] = useField('activeBlockId');
  const isSelected = id === activeId;
  const setAsActive = partial(setFieldValue, 'activeBlockId', id);
  const [{ value: watermarkBlocks }] = useField('watermark_blocks');
  const isBlockCountAtMinimum = Object.keys(watermarkBlocks).length < 2;
  const [
    {
      value,
      value: {
        position_reference_point: positionReferencePoint,
        position_x: positionX,
        position_y: positionY,
        scroll_text: textScrollDirection = 'none',
        text_alignment: textAlign,
        font_size: textSize,
      } = {},
    },
  ] = useField(`watermark_blocks[${id}]`);
  const isScrolling = ['ltr', 'rtl'].includes(textScrollDirection);

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      id,
      initialX: positionX,
      initialY: positionY,
      initialReferencePoint: positionReferencePoint,
      initialBlockDimensions: blockDimensions,
      type: dragTypes.SESSION_WATERMARK_TEMPLATE_BLOCK,
    },
    canDrag: true,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <StyledDiv ref={drag}>
      <SessionWatermarkTemplateBlockPositionContainer
        ref={clientRectRef}
        isBlockCountAtMinimum={isBlockCountAtMinimum}
        isDebugModeEnabled={isDebugModeEnabled}
        isSelected={isSelected}
        isHidden={isDragging}
        isScrolling={isScrolling}
        isShareOnlyTemplate={isShareOnlyTemplate}
        onMouseDown={setAsActive}
        onFocus={setAsActive}
        positionMax={[width, height]}
        positionNormalized={[positionX, positionY]}
        positionReferencePoint={positionReferencePoint}
        scale={scale}
        textAlign={textAlign}
        errors={get(errors, ['watermark_blocks', id])}
        textSize={textSize}
      >
        <SessionWatermarkTemplateBlock
          positionMaxX={width}
          scaleX={scale}
          value={value}
        />
      </SessionWatermarkTemplateBlockPositionContainer>
    </StyledDiv>
  );
};

export default SessionWatermarkTemplateBlockDragSource;
