import React from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { trackButtonClick } from 'analytics';
import Dialog, {
  dialogTypes,
} from '@frameio/components/src/styled-components/Dialog';
import Button from '@frameio/components/src/styled-components/Button';
import { redirectToNext } from 'utils/router';
import StartAccountIllustration from '../StartAccount/start-account-setup.svg';
import { SETUP_ACCOUNT_FLOW } from '../SetupAccountFlow';

// analytics page name passed to segment
export const START_ACCOUNT_STEP = 'start-account-step';

const TEXT = {
  headline: 'Visit V4 beta or set up your own account',
  message:
    'It appears you only have access to accounts which have upgraded to Frame.io Version 4 beta. Click below to see them in V4 beta or continue with creating your own account.',
  v4CTA: 'Go to V4 beta',
  createV3Account: 'Continue with your account',
};

const StyledDialog = styled(Dialog)`
  height: ${(p) => p.theme.spacing.units(50)};
  padding: ${(p) => p.theme.spacing.medium};
`;

const StartAccountIllustrationContainer = styled(Flex)`
  margin-top: ${(p) => p.theme.spacing.tiny};
  margin-bottom: ${(p) => p.theme.spacing.medium};
`;

const Header = styled.span`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: ${(p) => p.theme.fontSize[3]};
  color: ${(p) => p.theme.color.coolBlack};
  margin-bottom: ${(p) => p.theme.spacing.units(2)};
`;

const Copy = styled.p`
  color: ${(p) => p.theme.color.gray};
  text-align: center;
  padding: 0 10px;
`;

export default function StartAccountOrGoToV4({ startFlow, accountId }) {
  return (
    <StyledDialog
      type={dialogTypes.NONE}
      primaryButton={
        <Button
          primary
          onClick={() => {
            redirectToNext();
          }}
        >
          {TEXT.v4CTA}
        </Button>
      }
      secondaryButton={
        <Button
          onClick={() => {
            trackButtonClick('start', SETUP_ACCOUNT_FLOW, START_ACCOUNT_STEP);
            startFlow(accountId);
          }}
        >
          {TEXT.createV3Account}
        </Button>
      }
    >
      <StartAccountIllustrationContainer justifyCenter>
        <StartAccountIllustration />
      </StartAccountIllustrationContainer>
      <Header>{TEXT.headline}</Header>
      <Copy>{TEXT.message}</Copy>
    </StyledDialog>
  );
}

StartAccountOrGoToV4.propTypes = {
  applyStarterTrial: PropTypes.func,
};

StartAccountOrGoToV4.defaultProps = {
  applyStarterTrial: noop,
};
