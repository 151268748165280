import React from 'react';
import { identity } from 'lodash';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import path from 'path';
import queryString from 'query-string';

import TrackedRoute from 'components/TrackedRoute';
import TrackedAuthedRoute, { AuthedRoute } from 'components/AuthedRoute';
import config from 'config';
import { redirectToAuth, redirectTo, mergeQueryParams } from 'utils/router';
import {
  setAuthCookies,
  getAuthTokenExpiryForAuthToken,
  getAuthTokenExpiredForAuthToken,
} from 'utils/auth';
import {
  ACCOUNT_URL,
  ACCOUNT_SEARCH_URL,
  COMPARE_URL,
  CONFIRM_EMAIL_URL,
  DO_NOT_SHARE_PRIVACY_INFO_URL,
  INBOX_URL,
  FORGOT_URL,
  NOT_FOUND_URL,
  OAUTH_CALLBACK_URL,
  PLAYER as PLAYER_URL,
  PRESENTATION_URL,
  PROJECT_C2C_CONNECTIONS_URL,
  PROJECT_URL,
  PROJECT_PRESENTATIONS_URL,
  PROJECT_LINKS_URL,
  RESET_URL,
  REVIEW_LINK_URL,
  ROOT_URL,
  SETTINGS_URL,
  SHARED_PROJECT_URL,
  SHARED_PROJECT_FOR_INVITE_URL,
  TEAM_URL,
  WELCOME_URL,
  MIGRATE_TO_V4_URL,
} from 'URLs';
import PulsingCircle from '@frameio/components/src/styled-components/PulsingCircle';
import NotFound from './pages/NotFound';
import ConfirmEmailContainer from './pages/ConfirmEmailContainer';
import AuthCallback from './pages/AuthCallback';
import DoNotSellPrivacyFormContainer from './pages/DoNotSellPrivacyFormContainer';
import OAuthContainer from './pages/OAuthContainer';
import LayoutWithNav from './pages/LayoutWithNav';

const LazyRootContainer = Loadable({
  loader: () => import(/* webpackChunkName: "root" */ './pages/RootContainer'),
  loading: PulsingCircle,
});
const LazyProjectContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "project" */ './pages/ProjectContainer'),
  loading: PulsingCircle,
});
const LazyLogoutContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "logout" */ './pages/LogoutContainer'),
  loading: () => null,
});
const LazySettingsContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "settings" */ './pages/SettingsContainer'),
  loading: PulsingCircle,
});
const LazySharedProjectContainer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "shared-project" */ './pages/SharedProjectContainer'
    ),
  loading: PulsingCircle,
});
const LazySharedProjectByInviteContainer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "shared-project-by-invite" */ './pages/SharedProjectByInviteContainer'
    ),
  loading: PulsingCircle,
});
const LazyPresentationV2Container = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "presentation-v2" */ './pages/PresentationContainer'
    ),
  loading: PulsingCircle,
});
const LazyReviewLinkRouteHandler = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "review-link-v2" */ './pages/ReviewLinkContainer'
    ),
  loading: PulsingCircle,
});
const LazyPlayerContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "player" */ './pages/PlayerContainer'),
  loading: PulsingCircle,
});
const LazySplitPlayerContainer = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "split-player" */ './pages/SplitPlayerContainer'
    ),
  loading: PulsingCircle,
});
const LazyAccountContainer = Loadable({
  loader: () =>
    import(/* webpackChunkName: "account" */ './pages/AccountContainer'),
  loading: PulsingCircle,
});
const LazyGetV4Container = Loadable({
  loader: () =>
    import(/* webpackChunkName: "get-v4" */ './pages/GetV4Container'),
  loading: PulsingCircle,
});

// eslint-disable-next-line no-underscore-dangle
const adobeCep = window.__adobe_cep__;

function redirectToWelcome() {
  const { x: userId, y: bearerToken, z: refreshToken } = queryString.parse(
    window.location.search
  );

  if (!bearerToken || !refreshToken) {
    // Redirect to auth if we don't have any params present
    redirectToAuth(null, false, {
      userId,
      authTokenExpiry: getAuthTokenExpiryForAuthToken(bearerToken),
      authTokenExpired: getAuthTokenExpiredForAuthToken(bearerToken),
      details: '/welcome',
      hasAuthToken: Boolean(bearerToken),
      hasRefreshToken: Boolean(refreshToken),
      location: window?.location?.href,
    });
    return null;
  }

  // Legacy Adobe App redirect from the SSO service
  if (adobeCep) {
    const extensionPath = adobeCep.getSystemPath('extension');
    const query = queryString.stringify({ x: userId, y: bearerToken });
    const url = path.join(extensionPath, `welcome?${query}`);

    window.location.assign(url);
  } else {
    setAuthCookies(bearerToken, refreshToken);
    redirectTo(ROOT_URL);
  }

  return null;
}

function redirectToReset() {
  const { search } = window.location;
  const query = queryString.parse(search);
  window.location.assign(
    `${config.authClientHost}${mergeQueryParams(RESET_URL, query)}`
  );
  return null;
}

function redirectToForgot() {
  window.location.assign(`${config.authClientHost}${FORGOT_URL}`);
  return null;
}

function redirectToPromotions() {
  const { promo_code: promoCode } = queryString.parse(window.location.search);

  if (promoCode) {
    window.location.assign(`${config.authClientHost}/promotions/${promoCode}`);
    return null;
  }

  window.location.assign(NOT_FOUND_URL);
  return null;
}

function RedirectToReviewLinkV2({
  location: { search }, // eslint-disable-line react/prop-types
  match: {
    params: { reviewLinkId },
  }, // eslint-disable-line react/prop-types
}) {
  let redirectPath = `/reviews/${reviewLinkId}`;

  // Note that legacy page does not have the ability to go to a specific
  // asset in a version stack
  const query = queryString.parse(search);
  const assetId = query.f || query.v;
  if (assetId) {
    redirectPath = `${redirectPath}/${assetId}`;
  }

  return <Redirect to={redirectPath} />;
}

export default function AppRoutes() {
  return (
    <Switch>
      <Route
        path={DO_NOT_SHARE_PRIVACY_INFO_URL}
        component={DoNotSellPrivacyFormContainer}
      />

      <TrackedAuthedRoute
        exact
        name="root"
        path={ROOT_URL}
        render={(props) => (
          <LayoutWithNav>
            <LazyRootContainer {...props} />
          </LayoutWithNav>
        )}
      />
      <TrackedAuthedRoute
        exact
        name="project"
        path={[
          PROJECT_C2C_CONNECTIONS_URL,
          PROJECT_PRESENTATIONS_URL,
          PROJECT_LINKS_URL,
          PROJECT_URL,
        ].filter(identity)}
        render={(props) => {
          // CORE-1385: Since the Project route is not a subroute of the account
          // route, when the user first lands on the Project route, and then
          // navigates to a different account, there's a full page loader while the
          // Project route JS bundle is being downloaded. So Let's preload
          // the Account Container to prevent this full page loader.
          LazyAccountContainer.preload();
          return (
            // eslint-disable-next-line react/prop-types
            <LayoutWithNav selectedProjectId={props.match.params.projectId}>
              <LazyProjectContainer {...props} />
            </LayoutWithNav>
          );
        }}
      />
      <TrackedAuthedRoute
        exact
        path={PLAYER_URL}
        component={LazyPlayerContainer}
        name="player"
      />
      <TrackedAuthedRoute
        exact
        path={COMPARE_URL}
        component={LazySplitPlayerContainer}
        name="split-player"
      />
      <TrackedAuthedRoute
        exact
        path={[ACCOUNT_URL, ACCOUNT_SEARCH_URL, TEAM_URL, INBOX_URL]}
        render={(props) => {
          // CORE-1385: Since the Project route is not a subroute of the Account
          // route, when the user first lands on the Account route, and then
          // navigates to a Project, there's a full page loader while the
          // Account route JS bundle is being downloaded. So Let's preload
          // the Project Container to prevent this full page loader.
          LazyProjectContainer.preload();
          return (
            <LayoutWithNav>
              <LazyAccountContainer {...props} />
            </LayoutWithNav>
          );
        }}
      />
      <TrackedAuthedRoute
        exact
        path={`${SETTINGS_URL}/:page?`}
        component={LazySettingsContainer}
        name="settings"
      />
      <TrackedAuthedRoute
        path={SHARED_PROJECT_FOR_INVITE_URL}
        component={LazySharedProjectByInviteContainer}
        name="join-shared-project-by-invite"
      />
      <TrackedAuthedRoute
        path={MIGRATE_TO_V4_URL}
        render={(props) => (
          <LayoutWithNav>
            <LazyGetV4Container {...props} />
          </LayoutWithNav>
        )}
        name="get-v4"
      />

      <AuthedRoute path={OAUTH_CALLBACK_URL} component={OAuthContainer} />

      <Route path="/auth/callback" component={AuthCallback} />

      <TrackedRoute
        exact
        path={PRESENTATION_URL}
        component={LazyPresentationV2Container}
        name="presentation"
      />
      <TrackedRoute
        path="/(f|v|d)/:linkId/:password?"
        render={({ match }) => (
          <Redirect to={`/presentations/${match.params.linkId}`} />
        )}
        name="legacy-presentation"
      />
      <TrackedRoute
        path={REVIEW_LINK_URL}
        component={LazyReviewLinkRouteHandler}
        name="review-link"
      />
      <Route path="/r/:reviewLinkId" component={RedirectToReviewLinkV2} />

      <Redirect to={WELCOME_URL} from="/(login|signup)/" />

      <Route path="/logout" component={LazyLogoutContainer} />
      <TrackedRoute
        path={CONFIRM_EMAIL_URL}
        component={ConfirmEmailContainer}
        name="confirm-email"
      />
      <Route path={WELCOME_URL} render={redirectToWelcome} />
      <Route path={FORGOT_URL} render={redirectToForgot} />
      <Route path={RESET_URL} render={redirectToReset} />
      <TrackedRoute
        path={SHARED_PROJECT_URL}
        component={LazySharedProjectContainer}
        name="join-shared-project"
      />
      <TrackedRoute
        path="/promotion"
        render={redirectToPromotions}
        name="promotion"
      />

      <TrackedRoute path="*" component={NotFound} name="404" />
    </Switch>
  );
}

export const testExports = {
  Redirect,
  redirectToForgot,
  redirectToPromotions,
  redirectToReset,
  RedirectToReviewLinkV2,
  redirectToWelcome,
};
