import { connect } from 'react-redux';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import StartAccountOrGoToV4 from './StartAccountOrGoToV4';
import { startFlow } from '../actions';

function mapStateToProps(state, { accountId }) {
  const account = accountEntitySelector(state, { accountId }) || {};
  return {
    displayNameForAccount: account.display_name,
    accountId,
  };
}

const mapDispatchToProps = {
  startFlow,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StartAccountOrGoToV4);
