import React from 'react';
import styled, { keyframes } from 'styled-components';
import Flex from 'styled-flex-component';
import { ellipsis } from 'polished';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { LARGE } from 'utils/mediaQueries';
import Pip from '@frameio/components/src/styled-components/Pip';
import Avatar from '@frameio/components/src/styled-components/Avatar';
import { Tooltip } from '@frameio/vapor';

export const SET_DISPLAY_NAME_MESSAGE =
  'Set your account name and logo in account settings.';

const pulseIn = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
  }
  45% {
    transform: scale(1.3);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const AvatarContainer = styled(Flex).attrs(() => ({
  alignCenter: true,
}))`
  position: relative;

  ${Pip} {
    position: absolute;
    top: ${({ isLarge }) => (isLarge ? '-4px' : '-3px')};
    right: ${({ isLarge }) => (isLarge ? '-4px' : '-3px')};
    width: ${({ theme, isLarge }) => isLarge && theme.spacing.tiny};
    height: ${({ theme, isLarge }) => isLarge && theme.spacing.tiny};
    box-shadow: 0 0 0 2px
      ${({ theme, isLarge }) =>
        isLarge ? theme.color.black : theme.color.white};
    animation: ${pulseIn} 0.8s cubic-bezier(0, 0.2, 0.4, 1.5);
  }
`;

export const StyledAvatar = styled(Avatar)`
  border-radius: ${(p) => p.theme.radius.default};
  font-size: ${(p) => p.theme.fontSize[3]};
`;

const LabelText = styled.span`
  color: #5b53ff;
  font-weight: bolder;
  max-height: 20px;
  font-size: 12px;
  border-radius: 4px;
  background-color: rgba(91, 83, 255, 0.25);
  margin-left: ${({ largeMode }) => (largeMode ? '8px' : '0px')};
  padding-top: ${({ largeMode }) => (largeMode ? '0px' : '2px')};
  margin-top: ${({ hasName, largeMode }) =>
    hasName || largeMode ? '0px' : '20px'};
  padding-left: 4px;
  padding-right: 4px;
`;

const AccountName = styled.span`
  ${({ hasName, theme }) =>
    hasName ? theme.fontStyle.body : theme.fontStyle.caption};
  color: ${({ hasName, theme }) =>
    hasName ? theme.color.almostBlack : theme.color.gray};
  ${({ hasName }) => hasName && ellipsis()};
  width: ${({ label }) => (label ? `${8 * 15}px` : `${8 * 17}px`)};
  margin-left: ${(p) => p.theme.spacing.tiny};
  padding-right: 8px;
`;

const AccountTitle = styled.span`
  ${({ hasName, theme }) =>
    hasName ? theme.fontStyle.body : theme.fontStyle.caption};
  color: ${({ hasName, theme }) =>
    hasName ? theme.color.almostBlack : theme.color.gray};
`;

export default function Account({
  accountId,
  color,
  image,
  name,
  size,
  version,
  shouldShowPip,
}) {
  const betaLabel = version === 4 ? 'Beta' : '';
  const title = name || SET_DISPLAY_NAME_MESSAGE;
  return (
    <Media query={LARGE}>
      {(isLarge) => (
        <Tooltip
          disabled={!isLarge}
          key={accountId}
          label={betaLabel}
          accountName={title}
          title={
            <span>
              <AccountTitle hasName={name && name.length > 0}>
                {title}
              </AccountTitle>
              {betaLabel && <LabelText largeMode>{betaLabel}</LabelText>}
            </span>
          }
          placement="right"
          shouldUsePortal
          offset={[0, 12]}
          delayIn={200}
        >
          <Flex center>
            <AvatarContainer isLarge={isLarge}>
              <StyledAvatar
                color={color}
                image={image}
                name={name ? name[0] : ''}
                size={size}
              />
              {shouldShowPip && <Pip />}
            </AvatarContainer>
            {!isLarge && (
              <Flex>
                <AccountName
                  label={betaLabel}
                  hasName={name && name.length > 0}
                >
                  {title}
                </AccountName>
                {betaLabel && (
                  <LabelText hasName={name && name.length > 0}>
                    {betaLabel}
                  </LabelText>
                )}
              </Flex>
            )}
          </Flex>
        </Tooltip>
      )}
    </Media>
  );
}

Account.propTypes = {
  accountId: PropTypes.string,
  color: PropTypes.string,
  hasNotifications: PropTypes.bool,
  image: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number.isRequired,
  version: PropTypes.number.isRequired,
};

Account.defaultProps = {
  accountId: null,
  color: null,
  hasNotifications: false,
  image: null,
  name: null,
};

export const testExports = {
  AccountName,
  StyledAvatar,
};
