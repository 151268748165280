import combineReducers from 'combine-reducers-global-state';
import { AUTHED_USER } from '@frameio/core/src/users/actions';
import entities from '@frameio/core/src/reducers';
import uploads from '@frameio/core/src/uploads/reducer';
import {
  accountMigrationEligibility,
  accountSettingsPermissions,
  currentAccount,
  lastViewedAccountId,
} from 'reducers/accounts';
import { currentFolder, folderAncestors } from 'reducers/folders';
import { currentProject, projectFolderTrees } from 'reducers/projects';
import currentTeam from 'reducers/teams';
import toasts from 'reducers/toasts';
import currentUser from 'components/AuthedRoute/currentUserReducer';
import simpleDialog from 'components/Dialog/SimpleDialog/reducer';
import adobeIntegrationConnectDialog from 'components/Dialog/AdobeIntegrationConnectDialog/reducer';
import aemRepositoryTrees from 'components/Dialog/AdobeIntegrationConnectDialog/Aem/reducer';
import customActionFormDialog from 'components/Dialog/CustomActionFormDialog/reducer';
import deletedAssets from 'components/DeletedAssetsMenu/reducer';
import modal from 'components/Modal/reducer';
import dashboardSidebar from 'components/DashboardSidebar/reducers';
import createProjectForm from 'components/ProjectForms/CreateProject/reducers';
import editProjectForm from 'components/ProjectForms/EditProject/reducers';
import { connectRouter } from 'connected-react-router';
import accountContainer from 'pages/AccountContainer/reducer';
import playerContainer from 'pages/PlayerContainer/reducers';
import projectContainer from 'pages/ProjectContainer/reducers';
import getV4Container from 'pages/GetV4Container/reducers';
import unconfirmedEmailLockout from 'components/UnconfirmedEmailLockout/reducer';
import reviewLinkEditor from 'components/ReviewLinkEditor/reducer';
import jumpTo from 'components/JumpTo/reducer';
import quicklook from 'components/Quicklook/reducer';
import manageSubscription from 'components/ManageSubscription/reducer';
import presentationContainer from 'pages/PresentationContainer/reducer';
import presentationEditor from 'components/PresentationEditor/reducer';
import trialCountdown from 'components/TrialCountdown/reducer';
import selectPlan from 'components/SelectPlanFlow/reducer';
import paymentFlow from 'components/PaymentFlow/reducer';
import reviewLinkContainer from 'pages/ReviewLinkContainer/reducer';
import settingsContainer from 'pages/SettingsContainer/reducer';
import paymentMethod from 'pages/SettingsContainer/BillingContainer/AddCreditCard/reducer';
import commentCard from 'components/PlayerContainers/DetailPane/CommentCard/reducers';
import commentComposer from 'components/CommentComposer/reducer';
import archiveProjectFlow from 'components/ArchiveProjectFlow/reducer';
import projectList from 'components/DashboardSidebar/ProjectList/reducer';
import setupAccountFlow from 'components/SetupAccountFlow/reducer';
import contextMenu from 'components/ContextMenuManager/reducers';
import inboxContainer from 'pages/AccountContainer/InboxContainer/reducers';
import userSearch from 'components/UserSearch/reducers';
import moveTo from 'components/AssetActions/MoveTo/reducers';
import notificationsPopover from 'components/NotificationsPopover/reducer';
import joinRequestsPopover from 'components/JoinRequestsPopover/reducer';
import cancelAccountFlow from 'components/CancelAccountFlow/reducer';
import reactivateSubscription from 'components/ReactivateSubscription/reducer';
import creditCardFormElements from 'components/CreditCardFormElements/reducer';
import updateBillingInfo from 'components/UpdateBillingInfo/reducer';
import asset from './assets';

// TODO(WK-96): Note that this combineReducers is different from the one provided by redux in that
// it passes the previous root state object as the third argument to all the reducers specified
// in this map. This is currently only necessary for projectContainer, which needs cross-slice
// access to the entities slice in its reducers. All other uses cases should continue using
// `combineReducers` from the `redux` package.
const appReducerFactory = (history) =>
  combineReducers({
    accountContainer,
    accountMigrationEligibility,
    accountSettingsPermissions,
    adobeIntegrationConnectDialog,
    aemRepositoryTrees,
    archiveProjectFlow,
    asset,
    cancelAccountFlow,
    contextMenu,
    commentCard,
    commentComposer,
    createProjectForm,
    creditCardFormElements,
    currentAccount,
    currentFolder,
    currentProject,
    currentTeam,
    currentUser,
    customActionFormDialog,
    dashboardSidebar,
    deletedAssets,
    editProjectForm,
    entities,
    folderAncestors,
    getV4Container,
    inboxContainer,
    joinRequestsPopover,
    jumpTo,
    lastViewedAccountId,
    manageSubscription,
    modal,
    moveTo,
    notificationsPopover,
    paymentFlow,
    paymentMethod,
    playerContainer,
    presentationContainer,
    presentationEditor,
    projectContainer,
    projectFolderTrees,
    projectList,
    quicklook,
    reactivateSubscription,
    reviewLinkContainer,
    reviewLinkEditor,
    router: connectRouter(history),
    selectPlan,
    settingsContainer,
    setupAccountFlow,
    simpleDialog,
    toasts,
    trialCountdown,
    unconfirmedEmailLockout,
    updateBillingInfo,
    uploads,
    userSearch,
  });
// 👋 Please keep this list sorted, thanks!

export default function rootReducerFactory(history) {
  const appReducer = appReducerFactory(history);
  return (state, action) => {
    // Reset store after logging out
    let newState = state;
    if (action.type === AUTHED_USER.LOG_OUT_SUCCESS) {
      newState = undefined;
    }
    return appReducer(newState, action);
  };
}
