import { call, put, takeLatest } from 'redux-saga/effects';
import { migrateAccountToV4 as migrateAccountToV4CoreSaga } from '@frameio/core/src/accounts/sagas';
import { ACCOUNT_MIGRATION } from '@frameio/core/src/accounts/actions';
import {
  setAccountMigrationInProgress,
  setAccountMigrationFailed,
} from './actions';

function* migrateAccountToV4({ payload }) {
  const { accountId } = payload;

  const { failure, success } = yield call(migrateAccountToV4CoreSaga, {
    accountId,
  });

  // success of this endpoint triggers an oban job
  // currently the user will have to refresh the page to see if the migration is complete
  if (failure) {
    yield put(setAccountMigrationFailed({ accountId }));
  } else if (success) {
    yield put(setAccountMigrationInProgress({ accountId }));
  }
}

export default [
  takeLatest(ACCOUNT_MIGRATION.MIGRATE_TO_V4.BASE, migrateAccountToV4),
];

export const testExports = {
  migrateAccountToV4,
};
