import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import styled from 'styled-components';
import { useDragLayer } from 'react-dnd';
import { useField } from 'formik';
import { types as dragTypes } from 'utils/dragAndDrop';
import SessionWatermarkTemplateBlock from './SessionWatermarkTemplateBlock';
import SessionWatermarkTemplateBlockPositionContainer from './SessionWatermarkTemplateBlockPositionContainer';

const StyledDiv = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 100;
  pointer-events: none;
`;

const SessionWatermarkTemplateBlockDragLayer = (props) => {
  const {
    height,
    isDebugModeEnabled,
    isShareOnlyTemplate,
    scale,
    width,
  } = props;
  const clientRectRef = React.useRef();
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const [
    {
      value,
      value: {
        position_reference_point: positionReferencePoint,
        position_x: positionX,
        position_y: positionY,
        scroll_text: textScrollDirection = 'none',
        text_alignment: textAlign,
        font_size: textSize,
      } = {},
    },
  ] = useField(`watermark_blocks[${get(item, 'id')}]`);
  const isScrolling = ['ltr', 'rtl'].includes(textScrollDirection);
  const isPreviewVisible =
    isDragging &&
    currentOffset &&
    initialOffset &&
    itemType === dragTypes.SESSION_WATERMARK_TEMPLATE_BLOCK;
  const positionOffset = isPreviewVisible
    ? [currentOffset.x - initialOffset.x, currentOffset.y - initialOffset.y]
    : [0, 0];

  return (
    <StyledDiv>
      <SessionWatermarkTemplateBlockPositionContainer
        ref={clientRectRef}
        isDebugModeEnabled={isDebugModeEnabled}
        isShareOnlyTemplate={isShareOnlyTemplate}
        isDragPreview
        isHidden={!isPreviewVisible}
        isScrolling={isScrolling}
        positionMax={[width, height]}
        positionNormalized={[positionX, positionY]}
        positionOffset={positionOffset}
        positionReferencePoint={positionReferencePoint}
        scale={scale}
        textAlign={textAlign}
        textSize={textSize}
      >
        <SessionWatermarkTemplateBlock
          isAnimationPaused
          positionMaxX={width}
          scaleX={scale}
          value={value}
        />
      </SessionWatermarkTemplateBlockPositionContainer>
    </StyledDiv>
  );
};

SessionWatermarkTemplateBlockDragLayer.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
};

export default SessionWatermarkTemplateBlockDragLayer;
