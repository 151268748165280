/* eslint-disable no-param-reassign */
import { all, takeLatest, put, spawn, call, select } from 'redux-saga/effects';
import Raven from 'raven-js';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { createAsyncSaga } from '@frameio/core/src/shared/sagas/helpers';
import moment from 'moment-timezone';
import { accountIdsForAccountSwitcherSelector } from 'pages/LayoutWithNav/AccountSwitcher/selectors';
import {
  hydratedAssetEntitySelector,
  hydratedAssetEntityInclDeletedSelector,
  hydratedChildAssetsSelector,
} from '@frameio/core/src/assets/selectors';
import {
  projectByTeamFetchStatusSelector,
  sharedProjectsFetchStatusSelector,
} from '@frameio/core/src/projects/selectors';
import { teamsByAccountFetchStatusSelector } from '@frameio/core/src/teams/selectors';
import {
  joinAccountRoom,
  joinNotificationsRoom as joinNotificationsRoomFromCore,
  leaveAccountRoom,
} from '@frameio/core/src/sockets/actions';
import { getFlagsByAccount } from '@frameio/core/src/featureFlags/sagas';
import { Flags, getFlags } from 'utils/featureFlags';
import { getAccountsForMe, getAccount } from '@frameio/core/src/accounts/sagas';
import {
  getProjectsForTeam as getProjectsForTeamCoreSaga,
  getSharedProjects as getSharedProjectsCoreSaga,
} from '@frameio/core/src/projects/sagas';
import { listSearchFiltersForAccount } from '@frameio/core/src/search/sagas';
import { listSessionWatermarkTemplatesForAccount } from '@frameio/core/src/sessionWatermarkTemplate/sagas';
import { getTeamsForAccount as getTeamsForAccountCoreSaga } from '@frameio/core/src/teams/sagas';
import fetchAllPages from '@frameio/core/src/shared/sagas/fetchAllPages';
import convertCountdownToDays from 'utils/plans/convertCountdownToDays';

import {
  planEntityForAccountIdSelector,
  subscriptionEntityForAccountIdSelector,
} from '@frameio/core/src/shared/selectors/relationships';
import { status } from '@frameio/core/src/shared/reducers/factories';
import { getStripeCustomer } from '@frameio/core/src/subscriptions/sagas';

import { ACCOUNT, NEW_SETTINGS_APP_PERMISSIONS } from 'actions/accounts';
import { getRoleForTeam } from 'sagas/roles';
import { currentUserEntitySelector } from 'selectors/users';
import Cookies from 'js-cookie';
import track, {
  RESTRICT_DATA_KEY,
  hasRestrictDataCookie as checkRestrictDataCookie,
  removeRestrictDataCookie,
} from 'analytics';
import config from 'config';
import {
  currentAccountEntitySelector,
  currentAccountSelector,
  sortedTeamIdsForAccountIdSelector,
  teamIdsWithAdminOrMemberRoleFromEntitySelector,
  totalStorageLimitForAccountSelector,
} from 'selectors/accounts';
import { highestAccountOrTeamRoleForAccountIdSelector } from 'selectors/roles';
import { isPaymentMethodStripeSelector } from 'components/UpdateBillingInfo/selectors';
import { formatBytes } from 'shared/filesizeHelpers';
import { getAndWaitForSubscriptionByAccountId } from 'sagas/subscriptions';
import { subscriptionAndLineItemsCostSelector } from 'components/ManageSubscription/selectors';
import {
  getPausedPromosIdsForPlanIds,
  monthlyItemCost,
} from 'utils/plans/plansHelpers';

import { subscriptionEntityByAccountIdSelector } from 'selectors/subscriptions';
import { setNewNotificationsCount } from 'components/NotificationsPopover/sockets';
import { isFreePlanWithUserMaxSelectedSelector } from 'components/SelectPlanFlow/selectors';
import { getAccountOveragesWhenFreePlanWithUserMaxSelected } from 'components/SelectPlanFlow/utils';
import { openSelectPlanFlowModal } from 'components/SelectPlanFlow/actions';
import { shouldSeePlanSelectionModalDuringTrial } from 'components/TrialCountdown/sagas';
import { getCardsByAccount } from '@frameio/core/src/cards/sagas';
import { cardsByAccountFetchStatusSelector } from '@frameio/core/src/cards/selectors';
import { supportedNotificationTypes } from 'components/NotificationsPopover/constants';
import { getPermissionsForAccount } from 'services/accounts';
import {
  didNotShowToday,
  isTrialEndingIn3Days,
  saveTrialCountdown,
} from './helpers';

function* getProjectsForTeam(teamId) {
  const projectsFetchStatus = yield select(projectByTeamFetchStatusSelector);
  if (projectsFetchStatus[teamId] !== status.SUCCESS) {
    yield call(
      fetchAllPages,
      (params) =>
        getProjectsForTeamCoreSaga(teamId, {
          filters: { archived: 'all' },
          hardDropFields: ['a.cover_asset'],
          ...params,
        }),
      50
    );
  }
}

function* getSharedProjectsForAccount(accountId) {
  const sharedProjectsFetchStatus = yield select(
    sharedProjectsFetchStatusSelector
  );
  if (sharedProjectsFetchStatus[accountId] !== status.SUCCESS) {
    yield call(getSharedProjectsCoreSaga, accountId, {
      params: { 'filter[account_id]': accountId },
    });
  }
}

function* getTeamsForAccount(accountId) {
  const teamsFetchStatus = yield select(teamsByAccountFetchStatusSelector, {
    accountId,
  });

  if (teamsFetchStatus !== status.SUCCESS) {
    try {
      yield call(
        fetchAllPages,
        (params) =>
          getTeamsForAccountCoreSaga(accountId, {
            include: 'user_role',
            role: 'member',
            sort: 'name',
            ...params,
          }),
        50
      );
    } catch (e) {
      // "Trying is the first step toward failure" - Homer J. Simpson
      // If the call to fetchAllPages fails (e.g., when a user is only a reviewer), don't
      // sweat it. Let's eat the error and carry with our lives as if nothing ever happened
    }
  }
}

// Handle any feature flags that require a page refresh due to third party
// libraries that can only be configured on initial page load e.g. Segment
function applyRefreshFlags(flags) {
  const hasRestrictDataCookie = checkRestrictDataCookie();
  const isDataRestrictionEnabled = flags['web.account_settings.restrict_data'];

  if (!isDataRestrictionEnabled && hasRestrictDataCookie) {
    return removeRestrictDataCookie();
  }

  if (isDataRestrictionEnabled && !hasRestrictDataCookie) {
    // User has switched to an account requiring the restrict_data mode
    // Reload so that analytics can be loaded with the appropriate trackers
    // removed
    Cookies.set(RESTRICT_DATA_KEY, 'true', {
      expires: 365,
      secure: true,
      domain: config.domain,
    });
    return window.location.reload();
  }
  return null;
}

function* getProjectsForAccount(accountId) {
  // Additionally, we want to ensure that if no last viewed project id is set for
  // the quokka account that that we fetch projects for the teams as normal.
  // TODO(Ben/CORE-2201):
  // 1. Consolidate this so that this is the behavior for all projects going forward.
  // 2. Update `hasFetchedAccountDataSelector` to consider an "optimal" amount of data
  //    having been fetched (e.g. first team and its projects?) to minimize the time
  //    spent in a loading state on Account Container entry.
  const { lastViewedProjectId } = yield select(currentAccountSelector);

  if (!lastViewedProjectId) {
    const teamIds = yield select(
      teamIdsWithAdminOrMemberRoleFromEntitySelector,
      { accountId }
    );

    yield all(teamIds.map((teamId) => call(getProjectsForTeam, teamId)));
  }

  // Fetch all shared projects for account
  yield call(getSharedProjectsForAccount, accountId);
}

export function* shouldFetchCardsByAccount(accountId) {
  const cardsFetchStatus = (yield select(cardsByAccountFetchStatusSelector))[
    accountId
  ];
  const hasFetchedCards = [status.SUCCESS, status.FAILURE].includes(
    cardsFetchStatus
  );
  if (!hasFetchedCards && accountId) {
    yield call(getCardsByAccount, accountId);
  }
}

// This saga automatically opens the plan selection modal when the user has 3 days
// or less left in their trial.
function* maybeOpenTrialCountdownOverlay(accountId) {
  if (!(yield call(shouldSeePlanSelectionModalDuringTrial, accountId))) {
    return;
  }

  const subscription = yield select(
    subscriptionEntityByAccountIdSelector,
    accountId
  ) || {};
  const countdown = yield call(
    convertCountdownToDays,
    subscription.promotion_expires_at
  ) || 0;

  const shouldShow = yield call(didNotShowToday, countdown);
  const endWithin3Days = yield call(isTrialEndingIn3Days, countdown);

  if (shouldShow && endWithin3Days) {
    yield put(openSelectPlanFlowModal(accountId, { source: 'trial ended' }));
    yield call(saveTrialCountdown, countdown);
  }
}

function* joinNotificationsRoomForAccount(accountId) {
  const onSuccess = ({ v3 }) => {
    if (typeof v3 !== 'number') return;
    setNewNotificationsCount({
      accountId,
      newNotificationsCount: v3,
    });
  };
  const params = {
    filters: {
      notification_types: supportedNotificationTypes,
    },
  };
  yield put(joinNotificationsRoomFromCore(accountId, onSuccess, params));
}

function* joinNotificationsRooms() {
  const accountIds = yield select(accountIdsForAccountSwitcherSelector);

  yield all(
    accountIds.map((accountId) =>
      call(joinNotificationsRoomForAccount, accountId)
    )
  );
}

export const getBetterUserMgmtAccountPermissions = createAsyncSaga(
  NEW_SETTINGS_APP_PERMISSIONS.GET,
  getPermissionsForAccount
);

function* setCurrentAccount({ payload: { accountId, prevAccountId } }) {
  if (prevAccountId) {
    yield put(leaveAccountRoom(prevAccountId));
  }
  if (!accountId) return;

  yield put(joinAccountRoom(accountId));
  yield call(joinNotificationsRooms);

  yield call(getFlagsByAccount, accountId, Object.values(Flags));
  const flagPermissions = yield select(getFlags);

  applyRefreshFlags(flagPermissions);

  yield call(getBetterUserMgmtAccountPermissions, accountId);

  const shouldCallGetStripeCustomer = yield select(
    isPaymentMethodStripeSelector,
    {
      accountId,
    }
  );

  if (shouldCallGetStripeCustomer) {
    yield call(getStripeCustomer, accountId);
  }

  // TODO: Account/Settings Pages still need to be migrated over to v2 -- for now,
  // we don't want to merge legacy and v2 team data in those pages unnecessarily.
  if (window.vc) {
    // If we are on Account/Settings Pages, we still need to fetch team role data for
    // a given account as we no longer have legacy role selectors, and these pages
    // have yet to be moved to v2 team entities.
    const teamIds = yield select(sortedTeamIdsForAccountIdSelector, {
      accountId,
    });
    yield all(
      teamIds.map((teamId) => call(getRoleForTeam, { payload: { teamId } }))
    );
  } else {
    // Let's retrieve v2 team entities when _not_ in the Account/Settings Pages
    // Fetch entity data for all teams belonging to the account
    yield call(getTeamsForAccount, accountId);
    yield call(listSessionWatermarkTemplatesForAccount, accountId);
  }

  try {
    Raven.setTagsContext({
      ...flagPermissions,
    });
  } catch (err) {
    Raven.captureException(err);
  }

  // If we are on the Account/Settings Pages, let's not retrieve any more data
  // (search filters, and hydrated asset data).
  if (window.vc) return;

  yield spawn(getProjectsForAccount, accountId);

  yield call(listSearchFiltersForAccount, accountId);
  yield call(hydratedAssetEntitySelector.clearCache);
  yield call(hydratedAssetEntityInclDeletedSelector.clearCache);
  yield call(hydratedChildAssetsSelector.clearCache);

  // This saga automatically opens the TrialCountdownOverlay if the user is a target user.
  // The saga needs to be called here, because by this point, the info required
  // to evaluate the conditions in the saga has been fetched (subscription, card on file, etc.).
  yield call(maybeOpenTrialCountdownOverlay, accountId);
}

export function* syncAccountData() {
  yield call(getAccountsForMe, {
    params: {
      join: 'collaborator,reviewer',
    },
  });
}

function apply75percentDiscount(planPrice) {
  return Math.ceil(planPrice * 0.25);
}

function getPlanDailyPrice(planPrice) {
  const numberOfDaysInMonth = 30;
  return Math.ceil(planPrice / numberOfDaysInMonth);
}

// When an account downgrades to a v5/v6 free plan, we check that the account will not go above the
// v5/v6 free plans limits. If it will, we need to indicate in our Drift flow which Account usage
// needs to be reduced. This function helps constructing the copy used in our Drift flow.
function getAccountOveragesCopyForDriftFlow(accountLimitOverages) {
  const limitOveragesTypes = accountLimitOverages.map(({ limit, type }) => {
    const isStorageLimit = type === 'storage' || type === 'archival storage';
    const formattedType = type.charAt(0).toUpperCase() + type.slice(1);
    const formattedLimit = isStorageLimit ? formatBytes(limit) : limit;

    // i.e. 2 GB Storage / 2 Projects / 2 Users
    return `${formattedLimit} ${formattedType}`;
  });

  if (!limitOveragesTypes.length) {
    return '';
  }

  // i.e. "2 projects, 2 GB storageand 2 users"
  if (limitOveragesTypes.length === 4) {
    return limitOveragesTypes.reduce((acc, limit, index) => {
      if (index === 0) {
        acc = limit;
      }
      if (index === 1 || index === 2) {
        acc = `${acc}, ${limit}`;
      }
      if (index === 3) {
        acc = `${acc} and ${limit}`;
      }
      return acc;
    }, '');
  }

  // i.e. "2 projects, 2 GB storage and 2 users"
  if (limitOveragesTypes.length === 3) {
    return limitOveragesTypes.reduce((acc, limit, index) => {
      if (index === 0) {
        acc = limit;
      }
      if (index === 1) {
        acc = `${acc}, ${limit}`;
      }
      if (index === 2) {
        acc = `${acc} and ${limit}`;
      }
      return acc;
    }, '');
  }

  // i.e. "2 projects and 2 users"
  if (limitOveragesTypes.length === 2) {
    return limitOveragesTypes.reduce((acc, limit, index) => {
      if (index === 0) {
        acc = limit;
      }
      if (index === 1) {
        acc = `${acc} and ${limit}`;
      }
      return acc;
    }, '');
  }

  // i.e. "2 projects"
  return limitOveragesTypes[0];
}

// getTrackingData is used by trackCancellationStarted
function* getTrackingData(action) {
  const currentUser = yield select(currentUserEntitySelector);
  const currentAccount = yield select(currentAccountSelector);
  const accountId = currentAccount.id;
  const account = yield select(accountEntitySelector, { accountId });
  const subscription = yield call(
    getAndWaitForSubscriptionByAccountId,
    accountId
  );
  const isAccountOwner = currentAccount.owner_id === currentUser.id;
  const plan = yield select(planEntityForAccountIdSelector, { accountId });
  const totalCost = yield select(subscriptionAndLineItemsCostSelector, {
    accountId,
    plan,
  });
  const isFreePlanWithUserMaxSelected = yield select(
    isFreePlanWithUserMaxSelectedSelector
  );
  const accounOveragesWhenDowngradingToFreePlanWithUserMax = yield call(
    getAccountOveragesWhenFreePlanWithUserMaxSelected
  );
  const accountOveragesForDriftFlow = yield call(
    getAccountOveragesCopyForDriftFlow,
    accounOveragesWhenDowngradingToFreePlanWithUserMax
  );
  const promoIdForPausedPlan = getPausedPromosIdsForPlanIds(
    plan.id,
    plan.version
  );
  const [firstName, lastName] = currentUser.name.split(' ');

  return {
    [`${action}_started_user_id`]: currentUser.id,
    [`${action}_started_first_name`]: firstName,
    [`${action}_started_last_name`]: lastName,
    [`${action}_started_email`]: currentUser.email,
    [`${action}_started_account_id`]: accountId,
    [`${action}_started_plan_id`]: plan.id,
    [`${action}_started_plan_name`]: plan.title,
    [`${action}_started_plan_frequency`]: plan.period,
    [`${action}_started_next_billing_date`]: new Date(
      subscription.next_bill_at
    ).toString(),
    [`${action}_started_billing_amount`]: totalCost,
    [`${action}_started_is_admin`]: isAccountOwner,

    // todo(WK-112): use normalized account.owner data when it's normalized
    [`${action}_started_admin_name`]: account.owner.name,
    [`${action}_started_admin_email`]: account.owner.email,
    [`${action}_started_collaborator_count`]: account.collaborator_role_count,
    [`${action}_started_team_member_count`]: account.member_count,
    [`${action}_started_project_count`]: account.project_count,
    [`${action}_started_storage`]: formatBytes(account.storage),
    [`${action}_started_plan_version`]: plan.version,

    // Recommended plan properties for automated Drift upsells
    [`${action}_started_paused_plan_id`]: plan.name,
    [`${action}_started_paused_plan_monthly_price`]: apply75percentDiscount(
      totalCost
    ),
    [`${action}_started_paused_plan_daily_price`]: apply75percentDiscount(
      getPlanDailyPrice(totalCost)
    ),
    [`${action}_started_paused_plan_promo_id`]: promoIdForPausedPlan,
    [`${action}_started_freemium_overage`]:
      isFreePlanWithUserMaxSelected && accountOveragesForDriftFlow,
  };
}

export function* trackCancellationStarted() {
  const currentUser = yield select(currentUserEntitySelector);
  const trackingData = yield call(getTrackingData, 'cancellation');

  // need to call identify to add the tracking data
  // onto the user's profile in segment
  yield call(analytics.identify, currentUser.id, trackingData);
  yield spawn(track, 'cancellation-started', trackingData);
  if (window.drift) {
    yield call(window.drift.api.setUserAttributes, trackingData);
  }
}

// makes an identify call when the user wants to upgrade
// or is already on an enterprise plan and tries to contact Frame.io.
// This will add the tracking Data attributes to the user in segment.
export function* identifyAccountForEnterprisePlan() {
  const { email: userEmail, id: userId, name: userName } = yield select(
    currentUserEntitySelector
  );

  const currentAccountEntity = yield select(currentAccountEntitySelector);
  const {
    id: accountId,
    inserted_at: accountInsertedAt,
    storage,
    member_count,
    collaborator_count,
  } = currentAccountEntity || {};

  const planEntity = yield select(planEntityForAccountIdSelector, {
    accountId,
  });
  const planData = planEntity || {};
  const planCost = monthlyItemCost(planData.period, planData.cost);

  // NOTE: The total member limit is a sum of the subscription member limit,
  // and its associated plan member limit for the given account.
  const subscriptionEntity = yield select(
    subscriptionEntityForAccountIdSelector,
    { accountId }
  );
  const { total_member_limit: totalMemberLimit } = subscriptionEntity || {};

  const storageLimit = yield select(totalStorageLimitForAccountSelector, {
    accountId,
  });

  const userRoleForCurrentAccount = yield select(
    highestAccountOrTeamRoleForAccountIdSelector,
    { accountId }
  );

  const [firstName, lastName] = userName.split(' ');

  const trackingData = {
    first_name: firstName,
    email: userEmail,
    last_name: lastName,
    account_id: accountId,
    user_id: userId,
    account_role: userRoleForCurrentAccount,
    account_created_date: moment(accountInsertedAt)
      .utc()
      .toISOString(),
    enterprise_chat_plan_name: planData.title,
    enterprise_chat_plan_price: planCost,
    enterprise_chat_usage_storage: storage,
    enterprise_chat_limit_storage: storageLimit,
    enterprise_chat_usage_team_members: member_count,
    enterprise_chat_limit_team_members: totalMemberLimit || 'unlimited',
    enterprise_chat_usage_collaborators: collaborator_count,
    enterprise_chat_limit_collaborators:
      planData.collaborator_limit || 'unlimited',
  };

  yield call(analytics.identify, userId, trackingData);
}

export function* getAccountWithVat(accountId) {
  yield call(getAccount, accountId, true);
}

export default [
  takeLatest(ACCOUNT.SET_CURRENT, setCurrentAccount),
  takeLatest(ACCOUNT.TRACK_CANCELLATION_STARTED, trackCancellationStarted),
  takeLatest(
    ACCOUNT.IDENTIFY_FOR_ENTERPRISE_PLAN,
    identifyAccountForEnterprisePlan
  ),
  takeLatest(ACCOUNT.GET_WITH_VAT, ({ payload: { accountId } }) =>
    getAccountWithVat(accountId)
  ),
];

export const testExports = {
  setCurrentAccount,
  getBetterUserMgmtAccountPermissions,
  getProjectsForAccount,
  getProjectsForTeam,
  getSharedProjectsForAccount,
  getTeamsForAccount,
  getTrackingData,
  getAccountOveragesCopyForDriftFlow,
  maybeOpenTrialCountdownOverlay,
  shouldFetchCardsByAccount,
  joinNotificationsRooms,
};
