import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MenuButton } from '@frameio/vapor';
import { IconProject, IconWorkspace } from '@frameio/vapor-icons';
import SettingsLink from 'components/SettingsLink';
import GlobeIcon from '@frameio/components/src/svgs/icons/24/globe.svg';
import PeopleIcon from '@frameio/components/src/svgs/icons/24/people.svg';
import PersonIcon from '@frameio/components/src/svgs/icons/24/person.svg';
import SettingsIcon from '@frameio/components/src/svgs/icons/settings.svg';
import SearchIcon from '@frameio/components/src/svgs/icons/24/search.svg';

const AppsIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99992 1.33325L1.33325 4.66659L7.99992 7.99992L14.6666 4.66659L7.99992 1.33325Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33325 11.3333L7.99992 14.6666L14.6666 11.3333"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33325 8L7.99992 11.3333L14.6666 8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const StyledIconProject = styled(IconProject)`
  margin-left: -4px;
  margin-right: -4px;
  margin-top: -4px;
`;

const StyledIconWorkspace = styled(IconWorkspace)`
  margin-left: -4px;
  margin-right: -4px;
  margin-top: -4px;
`;

const AccountNavigationSettings = ({ accountSettingsPermissions }) => {
  const {
    view_profile_page,
    view_projects_page,
    view_settings_app,
    view_teams_page,
    view_users_page,
  } = accountSettingsPermissions;

  return (
    <React.Fragment>
      {view_settings_app && (
        <React.Fragment>
          {view_users_page && (
            <SettingsLink
              asComponent="a"
              pageKey="MANAGE_USERS"
              viewSettingsApp={view_settings_app}
            >
              {({ href, handleClick }) => (
                <MenuButton
                  as="a"
                  href={href}
                  onClick={handleClick}
                  isLink
                  iconBefore={<PeopleIcon width={16} height={16} />}
                  details="Add / manage users"
                >
                  Manage users
                </MenuButton>
              )}
            </SettingsLink>
          )}
          {view_teams_page && (
            <SettingsLink
              asComponent="a"
              pageKey="MANAGE_TEAMS"
              viewSettingsApp={view_settings_app}
            >
              {({ href, handleClick }) => (
                <MenuButton
                  as="a"
                  href={href}
                  onClick={handleClick}
                  isLink
                  iconBefore={<StyledIconWorkspace height={24} />}
                  details="Add / manage teams"
                >
                  Manage teams
                </MenuButton>
              )}
            </SettingsLink>
          )}
          {view_projects_page && (
            <SettingsLink
              asComponent="a"
              pageKey="MANAGE_PROJECTS"
              viewSettingsApp={view_settings_app}
            >
              {({ href, handleClick }) => (
                <MenuButton
                  as="a"
                  href={href}
                  onClick={handleClick}
                  isLink
                  iconBefore={<StyledIconProject height={24} />}
                  details="Add / manage projects"
                >
                  Manage projects
                </MenuButton>
              )}
            </SettingsLink>
          )}
          <SettingsLink
            asComponent="a"
            pageKey="TEAM_DIRECTORY"
            viewSettingsApp={view_settings_app}
          >
            {({ href }) => (
              <MenuButton
                as="a"
                href={href}
                isLink
                iconBefore={<SearchIcon width={16} height={16} />}
                details="View Teams in your organization"
              >
                Team Directory
              </MenuButton>
            )}
          </SettingsLink>
          <SettingsLink
            asComponent="a"
            pageKey="BRANDING"
            viewSettingsApp={view_settings_app}
          >
            {({ href }) => (
              <MenuButton
                as="a"
                href={href}
                isLink
                iconBefore={<GlobeIcon width={16} height={16} />}
                details="Branding for your account"
              >
                Branding
              </MenuButton>
            )}
          </SettingsLink>
        </React.Fragment>
      )}
      {!view_settings_app && (
        <SettingsLink asComponent="a" pageKey="USERS">
          {({ href }) => (
            <MenuButton
              as="a"
              href={href}
              isLink
              iconBefore={<PeopleIcon width={16} height={16} />}
              details="Add / manage users & teams"
            >
              Add members
            </MenuButton>
          )}
        </SettingsLink>
      )}
      <SettingsLink
        asComponent="a"
        pageKey="BILLING"
        viewSettingsApp={view_settings_app}
      >
        {({ href }) => (
          <MenuButton
            as="a"
            href={href}
            isLink
            iconBefore={<SettingsIcon width={16} height={16} />}
            details="Usage, billing, branding, teams"
          >
            Account settings
          </MenuButton>
        )}
      </SettingsLink>
      {!view_profile_page && (
        <SettingsLink asComponent="a" pageKey="ACCOUNT">
          {({ href }) => (
            <MenuButton
              as="a"
              Expand
              Down
              href={href}
              isLink
              iconBefore={<PersonIcon width={16} height={16} />}
              details="Email, profile, notifications"
            >
              Personal settings
            </MenuButton>
          )}
        </SettingsLink>
      )}
      {view_profile_page && (
        <SettingsLink
          asComponent="a"
          pageKey="MANAGE_PROFILE"
          viewSettingsApp={view_settings_app}
        >
          {({ href, handleClick }) => (
            <MenuButton
              as="a"
              href={href}
              isLink
              iconBefore={<PersonIcon width={16} height={16} />}
              details="Email, profile, notifications"
              onClick={handleClick}
            >
              Personal settings
            </MenuButton>
          )}
        </SettingsLink>
      )}
      <SettingsLink asComponent="a" pageKey="APPS_INTEGRATIONS">
        {({ href }) => (
          <MenuButton
            href={href}
            as="a"
            isLink
            iconBefore={<AppsIcon width={16} height={16} />}
            details="Creative Cloud, FCP X, Slack"
          >
            Apps & integrations
          </MenuButton>
        )}
      </SettingsLink>
    </React.Fragment>
  );
};

AccountNavigationSettings.defaultProps = {
  accountSettingsPermissions: {
    view_projects_page: false,
    view_settings_app: false,
    view_teams_page: false,
    view_users_page: false,
  },
};

AccountNavigationSettings.propTypes = {
  accountSettingsPermissions: PropTypes.object,
};

export const testExports = {};

export default AccountNavigationSettings;
