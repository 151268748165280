import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { noop } from 'lodash';
import { canSeeDevToolsSelector as canUseMockTestingFlowSelector } from 'components/DevelopmentTools/OpenDevTools/selectors';
import Button from '@frameio/components/src/styled-components/Button';
import DeviceSVG from '@frameio/components/src/svgs/illustrations/c2c-device.svg';
import {
  verifySession,
  confirmSession,
} from '@frameio/core/src/projectDevices/services';
import { projectDeviceEntitySelector } from '@frameio/core/src/projectDevices/selectors';
import { MEDIUM_DOWN } from 'utils/mediaQueries';
import { closeModal } from 'components/Modal/actions';
import FIOSelect from 'generators/FIOSelect';
import AuthenticationGraphic, {
  AUTH_GRAPHIC_VARIANTS,
} from './AuthenticationGraphic';
import {
  getProjectDevice,
  updateProjectDevice,
  resetProjectDeviceAuthorization,
} from '../actions';
import { currentAuthorizationIdSelector } from '../selectors';
import {
  AuthorizationFlowModal,
  FOOTER_VARIANTS,
} from './AuthenticationFlowModal';
import ExpirationInput from './ExpirationInput';
import UserCode, { USER_CODE_LENGTH } from './UserCode';

const VIEWS = {
  prompt: 'prompt',
  enterPairingCode: 'enter_pairing_code',
  authorize: 'authorize',
  authenticating: 'authenticating',
  success: 'success',
};

// Default to what the BE already "defaults" to:
const FALLBACK_TIMEZONE = 'America/New_York';

// Polling interval to check for Authorization Status change.
const POLLING_INTERVAL = 1500;
const MAX_ALLOWED_POLL_ATTEMPTS = 40;
let POLL_ATTEMPTS = 0;
let STATUS_POLLING_INTERVAL_ID = null;

const TEXT = {
  [VIEWS.prompt]: {
    title: 'Set device to pairing mode',
    body:
      'Please connect your Cloud Device to the internet via Ethernet or Wi-Fi, and find your device’s 6-digit pairing code.',
    button: {
      primary: 'Device ready to connect',
    },
  },
  [VIEWS.enterPairingCode]: {
    title: 'Enter the device pairing code',
  },
  [VIEWS.authorize]: {
    title:
      'Authorize this external device to integrate with this Frame.io project?',
    body: 'This device will be able to upload assets to Frame.io.',
    button: {
      primary: 'Authorize',
      secondary: 'Deny',
    },
  },
  [VIEWS.authenticating]: {
    body: 'Authenticating your device...',
  },
  [VIEWS.success]: {
    title: 'Success! Your device is connected',
    body:
      'All recorded footage will be uploaded to a generated folder structure. These files can be moved, but the upload destination cannot be changed.',
    button: {
      primary: 'Finish',
    },
  },
  error: {
    invalidUserCode: 'Invalid pairing code, please try again',
    failureAuthorizing: 'Failed to authorize device, please try again',
  },
  deviceDocumentation: 'Device Documentation',
};

const DEVICE_DOCUMENTATION_LINK =
  'https://support.frame.io/en/articles/4873371-c2c-set-cloud-devices-to-pairing-mode';

const MOCK = {
  authorizing_user: {
    name: 'AC',
    profile_image: '',
  },
  device: {
    model: {
      image_128:
        'https://static-assets.frame.io/promo/brand-logos/universal-logo.png',
    },
    name: 'CRAZY_LONG_DEVICE_NAME_FOR_TESTING',
  },
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(p) => p.theme.spacing.units(42)};

  @media ${MEDIUM_DOWN} {
    max-width: ${(p) => p.theme.spacing.units(40)};
    width: 90%;
  }
`;

const BodyIcon = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.small};
`;

const BodyTitle = styled.div`
  margin-right: ${(p) => p.theme.spacing.small};
  margin-left: ${(p) => p.theme.spacing.small};
  margin-bottom: ${(p) => p.theme.spacing.small};
  color: ${(p) => p.theme.color.white};
  font-size: ${(p) => p.theme.fontSize[2]};
  text-align: center;
`;

const BodyText = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.small};
  color: ${(p) => p.theme.color.gray};
  font-size: ${(p) => p.theme.fontSize[1]};
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: ${(p) => (p.shouldFill ? '100%' : 'auto')};
`;

const StyledButton = styled(Button)`
  flex: 1;
  margin: ${(p) => p.theme.spacing.micro};
  height: ${(p) => p.theme.spacing.large};
`;

const Buttons = ({ primary, secondary, shouldFill }) => {
  return (
    <ButtonContainer shouldFill={shouldFill}>
      {!!secondary && (
        <StyledButton dark large onClick={secondary.callback}>
          {secondary.text}
        </StyledButton>
      )}
      {!!primary && (
        <StyledButton primary large onClick={primary.callback}>
          {primary.text}
        </StyledButton>
      )}
    </ButtonContainer>
  );
};

const AuthenticationFlow = ({ projectId, projectName }) => {
  const dispatch = useDispatch();
  const [viewState, setViewState] = useState(VIEWS.prompt);
  const [userCode, setUserCode] = useState('');
  const [deviceVerification, setDeviceVerification] = useState({});
  const [footerProps, setFooterProps] = useState({
    variant: FOOTER_VARIANTS.info,
    message: TEXT.deviceDocumentation,
    link: DEVICE_DOCUMENTATION_LINK,
  });

  /**
   * Because C2C requires real hardware to obtain a user code, this allows
   * internal Frame.io users with a support tool role to test the authentication
   * flow.
   */
  const canUseMockTestingFlow = useSelector(canUseMockTestingFlowSelector);
  const projectDeviceId = useSelector(currentAuthorizationIdSelector);

  /**
   * Once successful project device will be defined,
   * and need to be updated with the correct timezone.
   */
  const projectDevice = useSelector((state) =>
    projectDeviceEntitySelector(state, { projectDeviceId })
  );

  async function setUserCodeCallback(newUserCode) {
    setFooterProps({});
    setUserCode(newUserCode);

    if (newUserCode.length === USER_CODE_LENGTH) {
      try {
        if (
          canUseMockTestingFlow &&
          ['000000', '999999'].includes(newUserCode)
        ) {
          setDeviceVerification(MOCK);
        } else {
          const response = await verifySession(newUserCode, projectId);
          setDeviceVerification(response);
        }

        setViewState(VIEWS.authorize);
        setFooterProps({ variant: FOOTER_VARIANTS.none });
      } catch {
        setFooterProps({
          variant: FOOTER_VARIANTS.error,
          message: TEXT.error.invalidUserCode,
        });
        setUserCode('');
      }
    }
  }

  /**
   * Poll for authorization status before moving on to success.
   * We set a number of attempts to stop polling.
   * The `useEffect` will handle changing state once the device
   * has an authorized status.
   */
  const pollAuthorizationStatus = (deviceId) => {
    STATUS_POLLING_INTERVAL_ID = setInterval(() => {
      const hasError = !deviceId || POLL_ATTEMPTS > MAX_ALLOWED_POLL_ATTEMPTS;

      if (canUseMockTestingFlow && userCode === '000000') {
        clearInterval(STATUS_POLLING_INTERVAL_ID);
        return;
      }

      if (canUseMockTestingFlow && userCode === '999999') {
        clearInterval(STATUS_POLLING_INTERVAL_ID);
        throw new Error();
      }

      if (hasError) {
        clearInterval(STATUS_POLLING_INTERVAL_ID);
        setFooterProps({
          variant: FOOTER_VARIANTS.error,
          message: TEXT.error.failureAuthorizing,
        });
      }

      POLL_ATTEMPTS += 1;
      dispatch(getProjectDevice(deviceId, projectId));
    }, POLLING_INTERVAL);
  };

  async function authorize() {
    const {
      device: { id: deviceId },
    } = deviceVerification;
    try {
      setViewState(VIEWS.authenticating);
      if (canUseMockTestingFlow && userCode === '000000') {
        noop();
      } else {
        const response = await confirmSession(
          true,
          deviceId,
          userCode,
          projectId,
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );
        setDeviceVerification(response);
      }
      pollAuthorizationStatus(deviceId);
    } catch {
      setFooterProps({
        variant: FOOTER_VARIANTS.error,
        message: TEXT.error.failureAuthorizing,
      });
    }
  }

  /**
   * A newly created device from the BE will return the default `FALLBACK_TIMEZONE`
   * Which will not reflect the timezone of the current client.
   * On mount of the success modal:
   * Update the project device with the client's timezone.
   */
  const setTimezoneToClientOffset = useCallback(() => {
    const { timezone_data } = FIOSelect;

    const now = new Date();
    const UtcAdjusted = -1;
    const clientOffsetInHours =
      Math.round(now.getTimezoneOffset() / 60) * UtcAdjusted;
    const offsetMatch = timezone_data.find(
      (availTimezonePreset) =>
        availTimezonePreset.offset === clientOffsetInHours
    );
    const bestMatchTimezone = offsetMatch
      ? offsetMatch.value
      : FALLBACK_TIMEZONE;

    if (bestMatchTimezone === projectDevice?.timezone) return;

    dispatch(
      updateProjectDevice(projectDeviceId, {
        timezone: bestMatchTimezone,
      })
    );
  }, [dispatch, projectDevice, projectDeviceId]);

  /**
   * Clear all states being retained during the flow.
   */
  const handleClose = () => {
    dispatch(resetProjectDeviceAuthorization(null));
    clearInterval(STATUS_POLLING_INTERVAL_ID);
    setViewState(VIEWS.prompt);
    dispatch(closeModal());
  };

  const buttons = {
    [VIEWS.prompt]: {
      primary: {
        callback: () => {
          setViewState(VIEWS.enterPairingCode);
          setFooterProps({});
        },
        text: TEXT[VIEWS.prompt].button.primary,
      },
    },
    [VIEWS.authorize]: {
      primary: {
        callback: () => authorize(),
        text: TEXT[VIEWS.authorize].button.primary,
      },
      secondary: {
        callback: handleClose,
        text: TEXT[VIEWS.authorize].button.secondary,
      },
    },
    [VIEWS.success]: {
      primary: {
        callback: handleClose,
        text: TEXT[VIEWS.success].button.primary,
      },
    },
  };

  /**
   * Update timezone and set the View to success when the device has
   * a successful status from polling `getProjectDevice`.
   */
  useEffect(() => {
    const deviceAuthorized =
      projectDevice && projectDevice?.status !== 'unauthorized';

    if (viewState === VIEWS.authenticating && deviceAuthorized) {
      clearInterval(STATUS_POLLING_INTERVAL_ID);

      setTimezoneToClientOffset();
      setViewState(VIEWS.success);
      setFooterProps({ variant: FOOTER_VARIANTS.none });
    }
  }, [setTimezoneToClientOffset, viewState, projectDevice, projectDeviceId]);

  return (
    <AuthorizationFlowModal footerProps={footerProps} onClose={handleClose}>
      {viewState === VIEWS.prompt && (
        <Body>
          <BodyIcon>
            <DeviceSVG />
          </BodyIcon>
          <BodyTitle>{TEXT[viewState].title}</BodyTitle>
          <BodyText style={{ width: '90%' }}>{TEXT[viewState].body}</BodyText>
          <Buttons {...buttons[viewState]} />
        </Body>
      )}
      {viewState === VIEWS.enterPairingCode && (
        <Body style={{ marginTop: '32px' }}>
          <BodyTitle>{TEXT[viewState].title}</BodyTitle>
          <UserCode
            currentValue={userCode}
            newValCallback={setUserCodeCallback}
          />
        </Body>
      )}
      {viewState === VIEWS.authorize && (
        <Body>
          <BodyTitle>{TEXT[viewState].title}</BodyTitle>
          <BodyText>{TEXT[viewState].body}</BodyText>
          <AuthenticationGraphic
            deviceVerificationResponse={deviceVerification}
            projectName={projectName}
            variant={AUTH_GRAPHIC_VARIANTS.AUTHORIZE}
          />
          <Buttons {...buttons[viewState]} shouldFill />
        </Body>
      )}
      {viewState === VIEWS.authenticating && (
        <Body>
          <BodyIcon>
            <DeviceSVG />
          </BodyIcon>
          <BodyTitle>{deviceVerification.device?.name}</BodyTitle>
          <BodyText>{TEXT[viewState].body}</BodyText>
        </Body>
      )}
      {viewState === VIEWS.success && (
        <Body>
          <BodyTitle>{TEXT[viewState].title}</BodyTitle>
          <BodyText>{TEXT[viewState].body}</BodyText>
          <AuthenticationGraphic
            deviceVerificationResponse={deviceVerification}
            projectName={projectName}
            variant={AUTH_GRAPHIC_VARIANTS.SUCCESS}
          />
          <ExpirationInput projectDeviceId={projectDeviceId} />
          <Buttons {...buttons[viewState]} shouldFill />
        </Body>
      )}
    </AuthorizationFlowModal>
  );
};

export default AuthenticationFlow;
