import { connect } from 'react-redux';
import { closeModal } from 'components/Modal/actions';
import { isAccountOnPlanWithUserMaxSelector } from 'selectors/accounts';
import { checkoutAndMigrationFlowsUpdatesEnabled } from 'utils/featureFlags';
import { startFlow, endFlow } from './actions';
import SelectPlanFlow from './SelectPlanFlow';

function mapStateToProps(state) {
  const isAccountOnPlanWithUserMax =
    checkoutAndMigrationFlowsUpdatesEnabled(state) ||
    isAccountOnPlanWithUserMaxSelector(state);

  return { isAccountOnPlanWithUserMax };
}

const mapDispatchToProps = {
  startFlow,
  endFlow,
  onCancel: closeModal,
  onComplete: closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPlanFlow);
