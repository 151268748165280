import React from 'react';
import { clamp, zipWith } from 'lodash';

const getAbsoluteCoordinate = (
  blockDimension,
  positionMax,
  positionNormalized,
  positionOffset,
  referencePointRelativeOffset,
  initialReferencePointRelativeOffset
) => {
  const initial = positionMax * positionNormalized;
  const selfOffset =
    (blockDimension / 100) *
    (initialReferencePointRelativeOffset - referencePointRelativeOffset);
  const coordinate = initial + positionOffset + selfOffset;
  return clamp(coordinate, 0, positionMax);
};

const getRelativeOffset = (positionReferencePoint = '') => {
  const relativeOffsetByReferencePoint = {
    bottom_center: [-50, -100],
    bottom_left: [0, -100],
    bottom_right: [-100, -100],
    middle_center: [-50, -50],
    middle_left: [0, -50],
    middle_right: [-100, -50],
    top_center: [-50, 0],
    top_left: [0, 0],
    top_right: [-100, 0],
  };
  return relativeOffsetByReferencePoint[positionReferencePoint] || [0, 0];
};

/**
 * Compute a reference point's absolute position and relative offset, where the
 * reference point is one of nine positions along the left/center/right and
 * top/middle/bottom of a rectangle with dynamic size. The sum of a reference
 * point's position and it's rectangle's offset describe the expected location
 * of a text watermark rendered by the Frame.io stream service. For more
 * information refer to https://github.com/Frameio/stream#features.
 *
 * ```
 * +----------------------------------+
 * |                                △ |
 * |                                │ |
 * |                          TOP=6px |
 * |                                │ |
 * | ◁──LEFT=18px──────────────────▷│ |
 * |                                ▽ |
 * |                A┄┄┄┄┄┄┄B┄┄┄┄┄┄┄C | ☜ RECT_OFFSET_C=[-100%,0]
 * |                ┆               ┆ |
 * |                D       E       F |
 * |                ┆               ┆ |
 * |                G┄┄┄┄┄┄┄H┄┄┄┄┄┄┄I |
 * +----------------------------------+
 * ```
 *
 * @param {*} args.clientRectRef - React Ref for rectangle DOM element.
 * @param {string} args.referencePoint - desired reference point for output
 * computations.
 * @param {string} args.initialReferencePoint - optionally provide to shift
 * output offset by the amount of initial offset.
 * @param {[number, number]} args.positionMax - position upper upper limits.
 * @param {[number, number]} args.positionNormalized - desired position as a
 * percentage from 0 to 1.
 * @param {[number, number]} args.positionOffset - optionally provide to shift
 * the output position by this number of pixels, for example `[15,-8]` to
 * increase output position for a drag operation 15 pixels to the right and 8
 * pixels up.
 * @returns {Object} result
 * @returns {[number, number]} result.position - reference point's pixel
 * coordinates on each axis, for example `[138,6]`.
 * @returns {[number, number]} result.referencePointOffset - relative position
 * of the rectangle's top left corner with respect to the reference point as an
 * integer percentage, for example reference point "top_right" gives the
 * rectangle offset `[-100,0]`.
 */
export default (args) => {
  const {
    clientRectRef,
    initialReferencePoint,
    positionMax,
    positionNormalized,
    positionOffset,
    referencePoint,
  } = args;

  // TODO: consider reducing DOM reads as a perf optimization
  const clientRect =
    clientRectRef && clientRectRef.current
      ? clientRectRef.current.getBoundingClientRect()
      : { width: 0, height: 0 };
  const blockDimensions = [clientRect.width, clientRect.height];

  const referencePointRelativeOffset = getRelativeOffset(referencePoint);
  const initialReferencePointRelativeOffset = getRelativeOffset(
    initialReferencePoint
  );
  const position = React.useMemo(
    () =>
      zipWith(
        blockDimensions,
        positionMax,
        positionNormalized,
        positionOffset,
        referencePointRelativeOffset,
        initialReferencePointRelativeOffset,
        getAbsoluteCoordinate
      ),
    [
      blockDimensions,
      initialReferencePointRelativeOffset,
      positionMax,
      positionNormalized,
      positionOffset,
      referencePointRelativeOffset,
    ]
  );

  return {
    position,
    relativeOffset: referencePointRelativeOffset,
  };
};
