import { createAction } from 'redux-actions';
import { generateActionTypes } from 'shared/actions/helpers';

const module = 'ACCOUNT_MIGRATION';

const ACCOUNT_MIGRATION = {
  ...generateActionTypes(module, ['IN_PROGRESS', 'FAILED']),
};

export default ACCOUNT_MIGRATION;

export const setAccountMigrationInProgress = createAction(
  ACCOUNT_MIGRATION.IN_PROGRESS,
  (accountId) => accountId
);

export const setAccountMigrationFailed = createAction(
  ACCOUNT_MIGRATION.FAILED,
  (accountId) => accountId
);
