import { connect } from 'react-redux';
import {
  accountMigrationEligibilitySelector,
  currentAccountSelector,
} from 'selectors/accounts';
import { accountEntitySelector } from '@frameio/core/src/accounts/selectors';
import { closeModal } from 'components/Modal/actions';
import { showErrorToast, showSuccessToast } from 'actions/toasts';
import { planEntityForAccountIdSelector } from '@frameio/core/src/shared/selectors/relationships';
import { currentUserEntitySelector } from 'selectors/users';
import MigrationModal from './MigrationModal';

export const mapDispatchToProps = {
  closeModal,
  showErrorToast,
  showSuccessToast,
};

export function mapStateToProps(state) {
  const currentUser = currentUserEntitySelector(state);

  const {
    canUserInitiateAccountMigration,
    isAccountEligibleForMigration,
  } = accountMigrationEligibilitySelector(state);
  const currentAccount = currentAccountSelector(state);
  const accountId = currentAccount.id;
  const account = accountEntitySelector(state, { accountId });
  const isEnterprise = Boolean(
    planEntityForAccountIdSelector(state, { accountId })?.enterprise
  );

  return {
    accountId,
    accountColor: account.account_default_color,
    accountImage: account.image_64,
    accountName: account.display_name,
    canUserInitiateAccountMigration,
    isAccountEligibleForMigration,
    isEnterprise,
    userEmail: currentUser?.email,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MigrationModal);
